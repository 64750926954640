/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createSelector } from "@ngrx/store";
import { AppActionTypes } from "../actions/app";
import {
  _getActiveApps,
  _getAppsCount,
  _getAppsUnreadCount,
  _getDateRange,
  _getFederatedApps,
  _getFilters,
  _getFirebaseToken,
  _getGroupBy, _getHideAllComments,
  _getIsActionProcessing,
  _getIsConnectedXMPP,
  _getIsLoggedIn,
  _getIsLoggedInUserLoading,
  _getLanguage,
  _getLastPhotoUpdate,
  _getMessageToReply,
  _getNetworkInformation,
  _getNotificationSettings,
  _getOnlineStatus,
  _getOrderBy,
  _getProjectData,
  _getSearchString,
  _getShowFilters,
  _getSidebarStatus,
  _getTags,
  _getTotalPerPage,
  _getUnreadOnly,
  _getUserContacts,
  _getUserGalContacts,
  _getUserProfile,
  _getViewBy,
  _IsDeviceReady,
  appReducer,
  AppState
} from "./app";
import { _getHasMoreDocuments, _getIsDocumentLoaded, _getIsDocumentLoading, documentReducer, DocumentState } from "./document";
import {
  _getBlockedBareIds,
  _getConversationConfig,
  _getConversationConfigLoaded,
  _getConversationForParticipants,
  _getConversationMembers,
  _getConversationOwner,
  _getConversationText,
  _getIsActivatedConversation,
  _getIsConversationBlocked,
  _getIsConversationDetailsVisible,
  _getIsLoaded,
  _getIsLoading,
  _getLastText,
  _getSelectedConversationId,
  _getSelectedMessageIds,
  conversationAdapter,
  conversationReducer,
  ConversationState
} from "./conversation";
import {
  _getAllConversationsUnreadCount,
  _getConversationMessageIds,
  _getConversationUnreadCount,
  _getIsConversationMessagesLoaded,
  _getIsConversationMessagesLoading,
  _getIsConversationMessagesOnLastPage,
  _getUnreadCount,
  conversationMessageReducer,
  ConversationMessageState
} from "./conversation-message";
import { Conversation } from "../common/models/conversation.model";
import { messageAdapter, messageReducer, MessageState } from "./message";
import {
  _getContactBaresByGroupName,
  _getFailedPhotoForBareId,
  _getFailedPhotos,
  _getOnlineContactsBareId,
  _getPhotoForBareId,
  _getStatusForBareId,
  _getVCardForBareId,
  _getVCards,
  contactAdapter,
  contactReducer,
  ContactState
} from "./contact";
import { MessageStatus } from "../common/models/message.model";
import { commentAdapter, commentReducer, CommentState } from "./comment";

export interface RootState {
  app: AppState;
  document: DocumentState;
  conversation: ConversationState;
  contact: ContactState;
  message: MessageState;
  comment: CommentState;
  conversationMessage: ConversationMessageState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  document: documentReducer,
  conversation: conversationReducer,
  contact: contactReducer,
  message: messageReducer,
  comment: commentReducer,
  conversationMessage: conversationMessageReducer

};

export function reset(reducer) {
  return function (state, action) {

    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}



/**
 *  App Related Reducers
 */
export const getAppState = state => state.app;

export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getSidebarStatus = createSelector(getAppState, _getSidebarStatus);
export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getIsDeviceReady = createSelector(getAppState, _IsDeviceReady);
export const getOnlineStatus = createSelector(getAppState, _getOnlineStatus);
export const getIsLoggedIn = createSelector(getAppState, _getIsLoggedIn);
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);
export const getFirebaseToken = createSelector(getAppState, _getFirebaseToken);
export const getUnreadOnly = createSelector(getAppState, _getUnreadOnly);
export const getHideAllComments = createSelector(getAppState, _getHideAllComments);
export const getOrderBy = createSelector(getAppState, _getOrderBy);
export const getViewBy = createSelector(getAppState, _getViewBy);
export const getGroupBy = createSelector(getAppState, _getGroupBy);
export const getSearchString = createSelector(getAppState, _getSearchString);
export const getLastPhotoUpdate = createSelector(getAppState, _getLastPhotoUpdate);
export const getActiveApps = createSelector(getAppState, _getActiveApps);
export const getNotificationSettings = createSelector(getAppState, _getNotificationSettings);
export const getAppsCount = createSelector(getAppState, _getAppsCount);
export const getAppsUnreadCount = createSelector(getAppState, _getAppsUnreadCount);
export const getLanguage = createSelector(getAppState, _getLanguage);
export const getIsActionProcessing = createSelector(getAppState, _getIsActionProcessing);
export const getMessageToReply = createSelector(getAppState, _getMessageToReply);
export const getIsConnectedXMPP = createSelector(getAppState, _getIsConnectedXMPP);
export const getNetworkInformation = createSelector(getAppState, _getNetworkInformation);
export const getTags = createSelector(getAppState, _getTags);
export const getTotalPerPage = createSelector(getAppState, _getTotalPerPage);
export const getDateRange = createSelector(getAppState, _getDateRange);
export const getShowFilters = createSelector(getAppState, _getShowFilters);
export const getFilters = createSelector(getAppState, _getFilters);
export const getProjectData = createSelector(getAppState, _getProjectData);

export const getLastPhotoUpdateByEmail = (state: AppState, email: string) => {
  return getLastPhotoUpdate(state)[email];
};

/**
 *  Document Related Reducers
 */
export const getDocumentState = state => state.document;
export const getIsDocumentLoading = createSelector(getDocumentState, _getIsDocumentLoading);
export const getHasMoreDocuments = createSelector(getDocumentState, _getHasMoreDocuments);
export const getIsDocumentLoaded = createSelector(getDocumentState, _getIsDocumentLoaded);

/**
 * Conversation Related Reducers
 */
export const getConversationState = state => state.conversation;

export const {
  selectIds: getConversationIds,
  selectEntities: getConversationEntities,
  selectAll: getAllConversation,
  selectTotal: getTotalConversation, // returns total count of conversation
} = conversationAdapter.getSelectors(getConversationState);

export const getConversations = getAllConversation;
export const getTotalConversations = getTotalConversation;


export const getIsConversationsLoading = createSelector(
  getConversationState,
  _getIsLoading
);


export const getIsConversationsLoaded = createSelector(
  getConversationState,
  _getIsLoaded
);

export const getArchivedConversations = createSelector(getAllConversation, conversations => {
  return conversations.filter((conv: Conversation) => conv.archived && !conv.deleted);
});

export const getUnArchivedConversations = createSelector(getAllConversation, conversations => {
  return conversations.filter((conv: Conversation) => !conv.archived && !conv.deleted);
});

export const getLastText = createSelector(
  getConversationState,
  _getLastText
);


export const getSelectedConversationId = createSelector(
  getConversationState,
  _getSelectedConversationId
);

export const getIsActivatedConversation = (state: RootState, target: string) => {
  return _getIsActivatedConversation(getConversationState(state), target);
};

export const getSelectedConversation = createSelector(
  getSelectedConversationId,
  getConversationEntities,
  (id, entities) => entities[id]
);

export const getConversationById = (state: RootState, bareId: string) => {
  return getConversationEntities(state)[bareId];
};

export const getBlockedBareIds = createSelector(
  getConversationState,
  _getBlockedBareIds
);

export const getConversationForParticipants = createSelector(
  getConversationState,
  _getConversationForParticipants
);

export const getIsConversationBlocked = (state: RootState, conversation: Conversation) => {
  return conversation && _getIsConversationBlocked(getConversationState(state), conversation.Target);
};


export const getSelectedMessageIds = createSelector(
  getConversationState,
  _getSelectedMessageIds
);

export const getIsSelctedConversationDetailsVisible = createSelector(
  getConversationState,
  getSelectedConversationId,
  _getIsConversationDetailsVisible
);

export const getSelectedConversationMembers = createSelector(
  getConversationState,
  getSelectedConversationId,
  _getConversationMembers
);

export const getConversationMembers = (state: RootState, target: string) => {
  return _getConversationMembers(getConversationState(state), target);
};

export const getConversationOwner = (state: RootState, target: string) => {
  return _getConversationOwner(getConversationState(state), target);
};

export const getConversationConfig = (state: RootState, target: string) => {
  return _getConversationConfig(getConversationState(state), target);
};

export const getSelectedConversationText = (state: RootState, target: string) => {
  return _getConversationText(getConversationState(state), target);
};

export const getIsConversationConfigLoaded = (state: RootState, target: string) => {
  return _getConversationConfigLoaded(getConversationState(state), target);
};

/**
 * Message Reducers
 */
export const getMessageState = state => state.message;
export const getConversationMessageState = state => state.conversationMessage;

export const getUnreadCounts = createSelector(
  getConversationMessageState,
  _getUnreadCount
);


export const {
  selectIds: getMessageIds,
  selectEntities: getMessageEntities,
  selectAll: getAllMessage,
  selectTotal: getTotalMessage, // returns total count of conversation
} = messageAdapter.getSelectors(getMessageState);

export const getPendingMessages = createSelector(
  getAllMessage,
  messages => messages.filter(msg => msg.status === MessageStatus.PENDING)
);

export const getMessageIdsByConversationTarget = (state: RootState, target: string) => {
  return _getConversationMessageIds(getConversationMessageState(state), target);
};

export const getMessagesByConversationTarget = (state: RootState, target: string) => {
  const ids = getMessageIdsByConversationTarget(state, target);
  const entities = getMessageEntities(state);
  return ids.map(id => entities[id])
    .filter(msg => !!msg)
    .filter(msg => !msg.isDeleted)
    .sort((msg1, msg2) => {
      return msg1.timestamp - msg2.timestamp;
    });
};

export const getLastMessageByConversationTarget = (state: RootState, target: string) => {
  const ids = getMessageIdsByConversationTarget(state, target);
  const entities = getMessageEntities(state);
  return ids.map(id => entities[id]).filter(msg => !!msg && !msg.startFile
    && !msg.vncTalkConference && !msg.vncTalkMuc && !msg.isDeleted
    && ["inactive", "paused", "composing"].indexOf(msg.chatState) === -1).sort((msg1, msg2) => {
      return msg2.timestamp - msg1.timestamp;
    })[0];
};

export const getIsLoadedByConversationTarget = (state: RootState, conv: Conversation) => {
  return _getIsConversationMessagesLoaded(getConversationMessageState(state), conv);
};

export const getIsLoadingByConversationTarget = (state: RootState, conv: Conversation) => {
  return _getIsConversationMessagesLoading(getConversationMessageState(state), conv);
};

export const getIsSelectedConversationMessagesLoaded = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getIsConversationMessagesLoaded
);

export const getIsSelectedConversationMessagesLoading = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getIsConversationMessagesLoading
);

export const getIsSelectedConversationMessagesOnLastPage = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getIsConversationMessagesOnLastPage
);

export const getAllConversationsUnreadCount = createSelector(
  getConversationMessageState,
  _getAllConversationsUnreadCount
);

export const getConversationUnreadCount = (state: RootState, conversation: Conversation) => {
  return _getConversationUnreadCount(getConversationMessageState(state), conversation);
};


export const getTotalUnreadCount = createSelector(
  getAllConversationsUnreadCount, (allUnread) => {
    let totalUnreadCount: number = 0;
    let keys = Object.keys(allUnread).filter(key => !key.startsWith("broadcast-"));
    keys.forEach((key) => {
      totalUnreadCount += allUnread[key];
    });
    return totalUnreadCount;
  }
);

export const getTotalBroadcastUnreadCount = createSelector(
  getAllConversationsUnreadCount, (allUnread) => {
    let totalUnreadCount: number = 0;
    let keys = Object.keys(allUnread).filter(key => key.startsWith("broadcast-"));
    keys.forEach((key) => {
      totalUnreadCount += allUnread[key];
    });
    return totalUnreadCount;
  }
);

export const getSelectedConversationUnreadCount = createSelector(
  getConversationMessageState,
  getSelectedConversation,
  _getConversationUnreadCount
);

export const getMessageById = (state: RootState, messageId: string) => {
  return getMessageEntities(state)[messageId];
};

/**
 * Contact Related Reducers
 */
export const getContactState = state => state.contact;
export const {
  selectIds: getContactIds,
  selectEntities: getContactEntities,
  selectAll: getContacts,
  selectTotal: getTotalContact, // returns total count of contact
} = contactAdapter.getSelectors(getContactState);

export const getTotalContacts = getTotalContact;
export const getIsContactLoading = createSelector(getContactState, _getIsLoading);
export const getIsContactLoaded = createSelector(getContactState, _getIsLoaded);

export const getContactFailedPhotos = createSelector(getContactState, _getFailedPhotos);

export const getAllContacts = (state: RootState) => {
  return getContacts(state);
};

export const getContactById = (state: RootState, bareId: string) => {
  return getContactEntities(state)[bareId];
};

export const getContactsByIds = (state: RootState, ids: string[]) => {
  return ids.map(id => getContactEntities(state)[id]).filter(v => !!v);
};

export const getContactPhotoById = (state: RootState, bareId: string) => {
  return _getPhotoForBareId(getContactState(state), bareId);
};

export const getContactVCardById = (state: RootState, bareId: string) => {
  return _getVCardForBareId(getContactState(state), bareId);
};

export const getVCards = createSelector(getContactState, _getVCards);

export const getMemberBaresByGroupName = createSelector(getContactState, _getContactBaresByGroupName);

export const getContactFailedPhotoById = (state: RootState, bareId: string) => {
  return _getFailedPhotoForBareId(getContactState(state), bareId);
};


export const getContactStatusById = (state: RootState, bareId: string) => {
  return _getStatusForBareId(getContactState(state), bareId);
};

export const getOnlineContactsBareId = createSelector(getContactState, _getOnlineContactsBareId);

export const getMembersByGroupId = (state: RootState, groupId: number) => {
  const contacts = getContacts(state);

  return contacts.filter(c => {
    return c.groups && c.groups.filter(g => g.id === groupId).length > 0;
  });
};

export const getAllUserContacts = createSelector(getAppState, _getUserContacts);
export const getAllUserGalContacts = createSelector(getAppState, _getUserGalContacts);



/**
 * Comment Reducers
 */
export const getCommentState = state => state.comment;

export const {
  selectIds: getCommentIds,
  selectEntities: getCommentEntities,
  selectAll: getAllComment,
  selectTotal: getTotalComment, // returns total count of conversation
} = commentAdapter.getSelectors(getCommentState);

export const getCommentById = (state: RootState, commentId: string) => {
  return getCommentEntities(state)[commentId];
};
