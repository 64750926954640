
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EnvironmentTheme } from "./environment.interface";

export const theme: EnvironmentTheme = {
  title: "VNCcommander",
  appLogo: "/assets/images/logo.png",
  textLogo: "<span class=\"vnc\">VNC</span><span class=\"mail\">commander</span>",
  theme: "default",
  aboutUsLogo: "/assets/images/app-icon.png",
  aboutText: "<span class=\"disable-select\">VNC</span><span class=\"product_name disable-select\">mail</span>",
  mobileTextLogo: "VNC",
  profileBackgroundImage: "/assets/images/profile-background.jpg",
  sentryUrl: "https://1be6826fb8cb42a7a713445b60ee280f@sentry-web.dev-k8s.vnc.de/3",
  firebase: {
    apiKey: "AIzaSyDDzx3hFF1A8Yw2tBrUSaZmuGibnVY5814",
    authDomain: "commander-dev-10b12.firebaseapp.com",
    projectId: "commander-dev-10b12",
    storageBucket: "commander-dev-10b12.appspot.com",
    messagingSenderId: "565496929654",
    appId: "1:565496929654:web:2f6a413f5b4ac4f8972e57",
    measurementId: "G-ZMLWSB5FCG"
  }
};
