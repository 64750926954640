/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "../../../environments/environment";
import { BrowserClient } from "@sentry/browser";
import { UserProfile } from "src/app/common/models/mail-models";
import { Conversation } from "../models/conversation.model";
import { Message, MessageStatus, OriginalMessage } from "../models/message.model";
import { CommanderConstants } from "./commander.constants";
import { TranslateService } from "@ngx-translate/core";
// import * as _ from "lodash";
import uniq from "lodash/uniq";
import { MediaType } from "../models/vnctalk.model";

const client = new BrowserClient({
  dsn: "https://1be6826fb8cb42a7a713445b60ee280f@sentry-web.dev-k8s.vnc.de/3",
});
const SENTRY_LOG_DOMAIN = "commander.vnc.biz";

export class CommonUtils {
  static isImage(fileName: string): boolean {
    const extension = fileName.substr((fileName.lastIndexOf(".") + 1));
    return CommanderConstants.imagesExtensions.indexOf(extension.toLowerCase()) !== -1;
  }

  static getBaseUrl() {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      return baseUrl.split("/www/")[0] + "/www";
    } else if (environment.isElectron) {
      return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/mail")[0];
    } else {
      return "";
    }
  }

  static validateMail(mail: string): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  static getFullUrl(url: string) {
    return CommonUtils.getBaseUrl() + url;
  }

  static getShortNameForMobile(str: string, size: number): string {
    let shortName: string = "";
    if (this.isOnMobileDevice()) {
      shortName = str.substr(0, size) + (str.length > 10 ? "...." : "");
    } else {
      shortName = str;
    }
    return shortName;
  }

  static htmlEscape(str: string): string {
    return str
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isOnIOSMobile() {
    return /iPhone|iPad|iPod/i
      .test(navigator.userAgent);
  }

  static isOnAndroid() {
    return typeof device !== "undefined" && device.platform && device.platform.toUpperCase() === "ANDROID";
  }

  static isOnNativeMobileDevice() {
    return CommonUtils.isOnIOS() || CommonUtils.isOnAndroid();
  }

  static isInsideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static escapeHTMLString(unescapedString: string): string {
    if (!unescapedString) {
      return "";
    }
    return unescapedString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static unEscapeHTMLString(str: string): string {
    if (!str) {
      return "";
    }
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  static escapeCarriagesAndNewline(unescapedString: string): string {
    return unescapedString.replace(/\r?\n/g, " ");
  }

  static getIcon(appName: string): string {
    let icon = "";
    let name = appName.replace("vnc", "VNC");
    switch (appName.toLowerCase()) {
      case "vncmail": icon = CommonUtils.getFullUrl("/assets/icon/new-icons/vncmail.svg"); break;
      case "vnctask": icon = CommonUtils.getFullUrl("/assets/icon/new-icons/vnctask.svg"); break;
      case "vncmcb": icon = CommonUtils.getFullUrl("/assets/icon/VNCmcb.svg"); break;
      case "vnccontacts": icon = CommonUtils.getFullUrl("assets/icon/new-icons/vnccontacts.svg"); break;
      case "vnctalk": icon = CommonUtils.getFullUrl("/assets/icon/new-icons/vnctalk.svg"); break;
      case "vnccalendar": icon = CommonUtils.getFullUrl("/assets/icon/new-icons/vnccalendar.svg"); break;
      case "vncproject": icon = CommonUtils.getFullUrl("/assets/icon/new-icons/vncproject.svg"); break;
      default: icon = CommonUtils.getFullUrl("/assets/icon/" + name + ".svg"); break;
    }
    return icon;
  }

  static getPackageName(appName: string): string {
    const packageName = `biz.vnc.${appName.toLowerCase()}`;

    return packageName;
  }

  static isValidAvtarSize(file: { size: number; }) {
    if (file.size < 2000000) {
      return true;
    } else {
      return false;
    }
  }


  static getAppUrl(appName: string): string {
    const availableiOSApps = {
      vncmail: "",
      vnctask: "itms-apps://itunes.apple.com/app/id1423089930",
      vnccontacts: ""
    };
    const availableAndroidApps = {
      vncmail: "",
      vnctask: "market://details?id=biz.vnc.vnctask",
      vnccontacts: ""
    };
    if (!CommonUtils.isOnAndroid()) {
      return availableiOSApps[appName.toLowerCase()];
    }
    return availableAndroidApps[appName.toLowerCase()];
  }

  static requestPermissions(): void {
    if (!cordova.plugins.permissions) {
      return;
    }
    const permissions = cordova.plugins.permissions;
    const checkVideoPermissionCallback = function (status: { hasPermission: any; }) {
      if (!status.hasPermission) {
        const errorCallback = function () {

        };
        permissions.requestPermission(
          permissions.CAMERA,
          function (s: { hasPermission: any; }) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkAudioPermissionCallback = function (status: { hasPermission: any; }) {
      if (!status.hasPermission) {
        const errorCallback = function () {

        };
        permissions.requestPermission(
          permissions.RECORD_AUDIO,
          function (s: { hasPermission: any; }) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkWriteExternalStoragePermissionCallback = function (status: { hasPermission: any; }) {
      if (!status.hasPermission) {
        const errorCallback = function () {

        };
        permissions.requestPermission(
          permissions.WRITE_EXTERNAL_STORAGE,
          function (s: { hasPermission: any; }) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    permissions.checkPermission(permissions.CAMERA, checkVideoPermissionCallback, null);
    permissions.checkPermission(permissions.RECORD_AUDIO, checkAudioPermissionCallback, null);
    permissions.checkPermission(permissions.WRITE_EXTERNAL_STORAGE, checkWriteExternalStoragePermissionCallback, null);
  }

  static sentryLog(text: string, time: number) {
    try {
      client.captureMessage(text);
    } catch (ex) {
      console.error(ex);
    }
  }

  static getCharacters(firstName, lastName) {
    if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
      const fName: string = firstName.trim();
      const lName: string = lastName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return (chr1 + chr2).trim();
    } else if (firstName && firstName !== undefined) {
      const fName: string = firstName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      return chr1;
    } else if (lastName && lastName !== undefined) {
      const lName: string = lastName.trim();
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return chr2;
    } else {
      return "NA";
    }
  }

  static getFullName(firstName: string, lastName: string): string {
    if (firstName && lastName) {
      return firstName.trim() + " " + lastName.trim();
    } else {
      if (firstName && firstName !== undefined) {
        return firstName.trim();
      } else if (lastName && lastName !== undefined) {
        return lastName.trim();
      }
    }
  }

  static escapeRegExpStr(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  static insertAt(base: string, insert: string, index: number) {
    return base.slice(0, index) + insert + base.slice(index);
  }

  static isAndroid() {
    return /android/i.test(navigator.userAgent || navigator.vendor) ? true : false;
  }

  static isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static parseUserProfile(contact) {
    const contactUser = typeof contact === "string"
      ? JSON.parse(contact)
      : contact;
    const user: UserProfile = {};
    user.firstName = contactUser.firstName;
    user.lastName = contactUser.lastName;
    if (Array.isArray(contactUser.email)) {
      user.email = contactUser.email[0];
    } else {
        user.email = contactUser.email;
    }
    user.fullName = contactUser.fullName;
    return user;
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static validateEmail(email) {
    // RFC822 version
    let sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
    let sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
    let sAtom = "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
    let sQuotedPair = "\\x5c[\\x00-\\x7f]";
    let sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
    let sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
    let sDomain_ref = sAtom;
    let sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
    let sWord = "(" + sAtom + "|" + sQuotedString + ")";
    let sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
    let sLocalPart = sWord + "(\\x2e" + sWord + ")*";
    let sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 email address spec
    let sValidEmail = "^" + sAddrSpec + "$"; // as whole string

    let reValidEmail = new RegExp(sValidEmail);

    return reValidEmail.test(email);
  }

  static linkify(inputText: string): string {
    if (!inputText) {
      return "";
    }
    return linkifyHtml(inputText, {
      defaultProtocol: "http",
      className: "open-new-window",
      target: {
        url: "_blank",
        email: null
      },
      formatHref: (href, type) => {
        if (type !== "email") {
          return href;
        }
        return "javascript:void(0)";
      },
      tagName: (href, type) => {
        if (type !== "email") {
          return "a";
        }
        return "span";
      }
    });
  }

  static isOnChrome() {
    return /chrome/.test(navigator.userAgent.toLowerCase());
  }

  static isOnSafari() {
    return /safari/.test(navigator.userAgent.toLowerCase()) && !this.isOnChrome();
  }

  static isOnFirefox() {
    return /firefox/.test(navigator.userAgent.toLowerCase());
  }

  static isOnIOS() {
    return typeof device !== "undefined" && !!device.platform && device.platform.toUpperCase() === "IOS";
  }

  static isOnIOSXFamily() {
    // https://www.theiphonewiki.com/wiki/Models#iPhone
    const isXFamily = ["iPhone10,3", "iPhone10,6", "iPhone11,8", "iPhone11,2", "iPhone11,6"].includes(device.model);
    return this.isOnIOS() && isXFamily;
  }

  static trimString(subject: string, length: number, suffix: string = "...") {
    if (subject.length <= length) {
      return subject;
    }

    const suffixLength = suffix.length;
    const trimLength = length - suffixLength;

    return subject.slice(0, trimLength - 1) + suffix;
  }

  static plainTextToHTML(str: string): string {
    if (str !== undefined && str !== null) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else {
      return "";
    }
  }

  static HTMLToPlainText(str: string): string {
    if (str !== undefined && str !== null) {
      str = str.replace(/<style([\s\S]*?)<\/style>/gi, "");
      str = str.replace(/<script([\s\S]*?)<\/script>/gi, "");
      str = str.replace(/<\/div>/ig, "\n");
      str = str.replace(/<\/li>/ig, "\n");
      str = str.replace(/<li>/ig, "  *  ");
      str = str.replace(/<\/ul>/ig, "\n");
      str = str.replace(/<\/p>/ig, "\n");
      str = str.replace(/<br\s*[\/]?>/gi, "\n");
      str = str.replace(/<[^>]+>/ig, "");
      return str;
    } else {
      return "";
    }
  }

  static limitContent(text: string, limit): string {
    if (text.length > limit) {
      for (let i = limit; i > 0; i--) {
        if (text.charAt(i) === " " && (text.charAt(i - 1) !== "," || text.charAt(i - 1) !== "." || text.charAt(i - 1) !== ";")) {
          return text.substring(0, i) + "...";
        }
      }
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }


  static beautifyMailContent(content: string): string {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, "text/html");
    htmlDoc.body.innerHTML = CommonUtils.linkify(htmlDoc.body.innerHTML);
    for (let element of Array.from(htmlDoc.querySelectorAll("style"))) {
      const dom = <HTMLElement>element;
      dom.remove();
    }
    for (let element of Array.from(htmlDoc.querySelectorAll("meta"))) {
      const dom = <HTMLElement>element;
      dom.remove();
    }
    for (let element of Array.from(htmlDoc.querySelectorAll("table"))) {
      const dom = <HTMLElement>element;
      dom.style.width = "auto";
      dom.style.maxWidth = "auto";
      dom.removeAttribute("width");
    }
    if (!!htmlDoc.body.getAttribute("style")) {
      const result = htmlDoc.body.innerHTML;
      const style = htmlDoc.body.getAttribute("style").replace(/\"/g, "'");
      htmlDoc.body.innerHTML = `<div class="content-wrapper" style="${style}">${result}</div>`;
    }
    return htmlDoc.body.innerHTML;
  }

  static replaceLinkToAnchor(content: string): string {
    if (content === "" || content === undefined) {
      return "";
    }
    return content.replace(/(?:https?\:\/\/|www\.)+(?![^\s]*?")([\w.,@?!^=%&amp;:()\/~+#-]*[\w@?!^=%&amp;()\/~+#-])?/ig, (url) => {
      const wrap = document.createElement("div");
      const anchor = document.createElement("a");
      let href = url;
      if (!url.startsWith("http") && !url.startsWith("https")) {
        href = "http://" + url;
      }
      anchor.href = href.replace(/&#64;/g, "@");
      anchor.target = "_blank";
      anchor.classList.add("open-new-window");
      anchor.innerHTML = url;
      wrap.appendChild(anchor);
      return wrap.innerHTML;
    });
  }

  static addClassToAnchor(content: string): string {
    if (content === "" || content === undefined) {
      return "";
    }
    const parser = new DOMParser();
    return content.replace(/<a [^>]+>([^<]+)<\/a>/ig, (aHref) => {
      const htmlDoc = parser.parseFromString(aHref, "text/html");
      const aTag = htmlDoc.getElementsByTagName("a")[0];
      aTag.setAttribute("class", "open-new-window");
      if (aTag.getAttribute("href") !== null) {
        const url = aTag.getAttribute("href");
        if (!url.startsWith("http") && !url.startsWith("https")) {
          const href = "http://" + url;
          aTag.setAttribute("href", href);
        }
      }
      return htmlDoc.body.innerHTML;
    });
  }

  static copyToClipboard(stringArray: String[]) {
    let str = stringArray.join("\n");
    if (environment.isCordova) {
      cordova.plugins.clipboard.copy(str);
    } else {
      window.Clipboard = (function (window, document, navigator) {
        let textArea,
          copy;

        function isOS() {
          return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
          textArea = document.createElement("textArea");
          textArea.value = text;
          document.body.appendChild(textArea);
        }

        function selectText() {
          let range,
            selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        }

        function copyToClipboard() {
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }

        copy = function (text) {
          createTextArea(text);
          selectText();
          copyToClipboard();
        };

        return {
          copy: copy
        };
      })(window, document, navigator);
      window.Clipboard.copy(str);
    }
  }

  static isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }
    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }
    // other browser
    return false;
  }

  static getDisplayNameFromLDAPEntry(user) {
    if (user.displayName && user.displayName.length > 0) {
      return user.displayName[0];
    }

    if (user.givenName && user.givenName.length > 0) {
      if (user.sn && user.sn.length > 0) {
        return user.givenName[0] + " " + user.sn[0];
      }

      return user.givenName[0];
    }

    if (user.sn && user.sn.length > 0) {
      return user.sn[0];
    }

    return "";
  }

  static mapMiddleware(conversation): Conversation {
    const contentObject = JSON.parse(conversation.Content);
    let content = CommonUtils.unEscapeHTMLString(contentObject.content);
    let timestamp = conversation.Timestamp * 1000;
    if (timestamp === 0 && conversation.updated_at) {
      timestamp = conversation.updated_at * 1000;
      contentObject.has_data = true;
    }
    let type = conversation.Target.indexOf("@conference") === -1 ? "chat" : "groupchat";
    if (conversation.Target.startsWith("broadcast-")) {
      type = "broadcast";
    }
    let conv: Conversation = {
      Target: conversation.Target,
      type: contentObject.type ? contentObject.type : type,
      archived: contentObject.archived || false,
      state: contentObject.state,
      is_persistent: contentObject.mucpersistent || false,
      incoming: contentObject.incoming || false,
      received_receipt: contentObject.received_receipt || false,
      Timestamp: timestamp,
      content: content,
      displayName: contentObject.displayname,
      groupChatTitle: contentObject.groupChatTitle || contentObject.displayname,
      unreadIds: conversation.unreadids,
      mute_sound: conversation.mute_sound,
      mute_notification: conversation.mute_notification,
      deleted: conversation.deleted,
      conferenceType: contentObject.x_vncConference ? contentObject.x_vncConference.conferenceType : "chat"
    };
    if (conversation.broadcast_title) {
      conv.broadcast_title = conversation.broadcast_title;
    }
    if (conversation.total_mentions) {
      conv.total_mentions = conversation.total_mentions;
    }
    if (conversation.unread_mentions) {
      conv.unread_mentions = conversation.unread_mentions;
    }
    return conv;
  }

  static getDefaultMail(user): string {
    let email = "";
    if (Array.isArray(user.email)) {
      email = user.email[0];
    } else {
      email = user.email.split(",")[0];
    }
    return email;
  }

  static convertToXmppMessage(message: any): Message {
    let htmlBody = message.htmlbody || "";
    const text = /<body xmlns=\"http:\/\/www.w3.org\/1999\/xhtml\">(.*)<\/body>/ig.exec(htmlBody);
    if (!!text && text[1]) {
      htmlBody = text[1];
    }
    const timestamp = new Date(message.date).getTime();
    let id;
    if (message.id) {
      const splitId = message.id.split("_");
      id = splitId[splitId.length - 1];
    } else {
      id = String(timestamp);
    }
    const response: Message = {
      id: id,
      from: message.from,
      fromJid: message.from,
      body: message.body,
      htmlBody: htmlBody,
      timestamp: timestamp,
      file: null,
      isForwarded: false,
      status: MessageStatus.DELIVERED,
      requestReceipt: true,
      tags: message.tags,
      to: message.to,
    };

    if (message.x_attachment) {
      const attachment = JSON.parse(message.x_attachment);
      response["attachment"] = attachment;
      if (attachment["$t"]) {
        response["attachment"] = JSON.parse(attachment["$t"]);
      }
    }

    if (message.broadcast) {
      response["broadcast"] = message.broadcast;
    }
    if (message["broadcast-sender"]) {
      response["broadcastSender"] = message["broadcast-sender"];
    }

    if (message.mention && message.mention !== "[]") {
      const mention = JSON.parse(message.mention);
      response["references"] = mention.map(m => {
        m = m.replace(/xmpp:+/ig, "");
        const data = {
          type: "mention",
          uri: `xmpp:${m}`
        };
        return data;
      });
    }
    if (message.sort_id) {
      response["sort_id"] = message.sort_id;
    }

    if (message.x_origMessage) {
      response["originalMessage"] = typeof message.x_origMessage === "string" ? JSON.parse(message.x_origMessage) : message.x_origMessage;
    }

    if (message.x_forwardMessage) {
      response["forwardMessage"] = typeof message.x_forwardMessage === "string"
        ? JSON.parse(message.x_forwardMessage) : message.x_forwardMessage;
      response["isForwarded"] = true;
    }

    if (message.x_vncConference) {
      response["vncTalkConference"] = JSON.parse(message.x_vncConference);
    }

    if (message.x_location) {
      response["location"] = JSON.parse(message.x_location);
    }

    if (message.x_replaceMsgId) {
      response["replace"] = { id: message.x_replaceMsgId };
    }

    if (message.room) {
      response["type"] = "groupchat";
    } else {
      response["type"] = "chat";
    }

    return response;
  }

  static isVideo(fileType: string): boolean {
    return CommanderConstants.videoExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isAudio(fileType: string): boolean {
    if (!fileType) {
      return false;
    }
    return CommanderConstants.audioExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isSupportedVideo(fileType: string): boolean {

    return CommanderConstants.supportedVideoExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static renderEmoji(text: string): string {
    wdtEmojiBundle.emoji.replace_mode = "unified";
    wdtEmojiBundle.emoji.allow_native = true;
    const result = wdtEmojiBundle.emoji.replace_emoticons(text);
    return result;
  }

  static renderBodyWithEmoji(content: string, translateService: TranslateService): string {
    content = this.renderEmoji(content);
    if (content.trim().startsWith("http") || content.trim().startsWith("https")) {
      const link = content.trim().toLocaleLowerCase();
      content = this.renderEmoji(":link: ") + translateService.instant("LINK");
      const extension = link.split(".").pop();
      if (extension && CommanderConstants.imagesExtensions.indexOf(extension) !== -1) {
        content = this.renderEmoji(":camera: ") + translateService.instant("PHOTO");
      } else if (extension && CommanderConstants.audioExtensions.indexOf(extension) !== -1) {
        content = this.renderEmoji(":studio_microphone: ") + translateService.instant("AUDIO");
      } else if (extension && CommanderConstants.videoExtensions.indexOf(extension) !== -1) {
        content = this.renderEmoji(":film_frames: ") + translateService.instant("VIDEO");
      }
    }

    return content;
  }

  static isMessagesChanged(newMessages: Message[], existingMessages: Message[]) {
    let changed = false;
    if (newMessages.length > 0 && existingMessages.length > 0) {
      if (newMessages.length !== existingMessages.length) {
        changed = true;
      } else {
        for (let i = 0; i < newMessages.length; ++i) {
          if (newMessages[i].body !== existingMessages[i].body ||
            newMessages[i].status !== existingMessages[i].status) {

            changed = true;
            break;
          }
        }
      }
    } else {
      changed = true;
    }
    return changed;
  }

  static parseMentions(text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp("@([a-zA-Z0-9\_\.\-]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
      matches = matches.map((match) => {
        return match.slice(1);
      });
      return uniq(matches.filter(v => CommonUtils.validateEmail(v)));
    } else {
      return [];
    }
  }

  static getMemberNames(text: string): string[] {
    return text.match(/([@][a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+)/g);
  }

  static randomId(length: number = 7, containNumbers: boolean = true) {
    let randomId = "";
    let dictionary = "abcdefghijklmnopqrstuvwxyz";

    if (containNumbers) {
      dictionary += "0123456789";
    }

    const dictionaryLength = dictionary.length;

    for (let i = 0; i < length; i++) {
      randomId += dictionary.charAt(Math.floor(Math.random() * dictionaryLength));
    }

    return randomId;
  }

  static getRandomNumbers() {
    let array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return array.join("");
  }

  static getMediaType(body: string): string {
    if ((body.lastIndexOf("aac") === body.length - 3) ||
      (body.lastIndexOf("amr") === body.length - 3) ||
      (body.lastIndexOf("aiff") === body.length - 4) ||
      (body.lastIndexOf("flac") === body.length - 4) ||
      (body.lastIndexOf("mp3") === body.length - 3) ||
      (body.lastIndexOf("oog") === body.length - 3) ||
      (body.lastIndexOf("wma") === body.length - 3) ||
      (body.lastIndexOf("wav") === body.length - 3)) {
      return MediaType.VOICE_MESSAGE;
    }

    if ((body.lastIndexOf("jpeg") === body.length - 4) ||
      (body.lastIndexOf("jpg") === body.length - 3) ||
      (body.lastIndexOf("gif") === body.length - 3) ||
      (body.lastIndexOf("png") === body.length - 3) ||
      (body.lastIndexOf("bmp") === body.length - 3) ||
      (body.lastIndexOf("svg") === body.length - 3) ||
      (body.lastIndexOf("tiff") === body.length - 4) ||
      (body.lastIndexOf("ico") === body.length - 3) ||
      (body.lastIndexOf("odi") === body.length - 3)) {
      return MediaType.IMAGE;
    }

    if ((body.lastIndexOf("3gp") === body.length - 3) ||
      (body.lastIndexOf("avi") === body.length - 3) ||
      (body.lastIndexOf("flv") === body.length - 3) ||
      (body.lastIndexOf("m4v") === body.length - 3) ||
      (body.lastIndexOf("mkv") === body.length - 3) ||
      (body.lastIndexOf("mov") === body.length - 3) ||
      (body.lastIndexOf("mp4") === body.length - 3) ||
      (body.lastIndexOf("mpeg") === body.length - 4) ||
      (body.lastIndexOf("mpg") === body.length - 3) ||
      (body.lastIndexOf("mts") === body.length - 3) ||
      (body.lastIndexOf("rm") === body.length - 2) ||
      (body.lastIndexOf("vob") === body.length - 3) ||
      (body.lastIndexOf("wmv") === body.length - 3)) {
      return MediaType.VIDEOS;
    }

    if (body.lastIndexOf("pdf") === body.length - 3) {
      return MediaType.PDF;
    }

    if ((body.lastIndexOf("txt") === body.length - 3) ||
      (body.lastIndexOf("rtf") === body.length - 3) ||
      (body.lastIndexOf("dat") === body.length - 3)) {
      return MediaType.TEXT;
    }

    if ((body.lastIndexOf("doc") === body.length - 3) ||
      (body.lastIndexOf("docx") === body.length - 4) ||
      (body.lastIndexOf("docm") === body.length - 4) ||
      (body.lastIndexOf("odt") === body.length - 3)) {
      return MediaType.DOC;
    }

    if ((body.lastIndexOf("xls") === body.length - 3) ||
      (body.lastIndexOf("xlr") === body.length - 3) ||
      (body.lastIndexOf("xlsx") === body.length - 4) ||
      (body.lastIndexOf("xlsm") === body.length - 3) ||
      (body.lastIndexOf("ods") === body.length - 3) ||
      (body.lastIndexOf("csv") === body.length - 3) ||
      (body.lastIndexOf("tsv") === body.length - 3)) {
      return MediaType.EXCEL;
    }

    if ((body.lastIndexOf("ppt") === body.length - 3) ||
      (body.lastIndexOf("pptx") === body.length - 4) ||
      (body.lastIndexOf("pps") === body.length - 3) ||
      (body.lastIndexOf("odp") === body.length - 3)) {
      return MediaType.PPT;
    }

    if ((body.lastIndexOf("rar") === body.length - 3) ||
      (body.lastIndexOf("tar") === body.length - 3)) {
      return MediaType.RAR;
    }

    if ((body.lastIndexOf("zip") === body.length - 3) ||
      (body.lastIndexOf("7z") === body.length - 2)) {
      return MediaType.ZIP;
    }
    return MediaType.ATTACHMENTS;
  }

  static removeMessageFromPending(mId: string): any {
    const pendingMessages = localStorage.getItem("pendingMessages");
    let msgs = [];
    if (pendingMessages) {
      msgs = JSON.parse(pendingMessages);
      msgs = msgs.filter(message => message.id !== mId);
    }
    localStorage.setItem("pendingMessages", JSON.stringify(msgs));
    return msgs;
  }

  static isBroadcastMessage(message): boolean {
    const isB = message.broadcast
      || (message.from && message.from.startsWith("broadcast-"))
      || (message.to && message.to.startsWith("broadcast-"))
      || (message.forwardMessage && message.forwardMessage.from && message.forwardMessage.from.startsWith("broadcast-"));

    return isB;
  }

  static getProfileFromStorage(): any {
    if (!!localStorage.profileUser) {
        return JSON.parse(localStorage.profileUser);
    } else {
        return {};
    }
  }

  static addTokenToRequest(url: string): string {
    if (environment.isCordova || environment.isElectron) {
      const token = localStorage.getItem("token");
      url += `&token=${token}`;
    }
    return url;
  }

  static addTokenToLink(url: string): string {
    const token = localStorage.getItem("token");
    url += `&token=${token}`;
    return url;
  }

  static renderRepliedMessage(origMessage: OriginalMessage, references?: any[]) {

  }

  static generateCachedBody(messageBody) {
    if (!messageBody) {
      console.warn("[CommonUtil][generateCachedBody] empty message body, ignore1");
      return messageBody;
    }
    messageBody = CommonUtils.processHTMLBody(messageBody);
    messageBody = CommonUtils.linkify(messageBody).replace(/\r?\n/g, "\\n").replace(/\\n/g, "<br />");
    messageBody = CommonUtils.processEmoji(messageBody.replace(/<\/p>/ig, " </p>").replace(/<p>:/ig, "<p> :"));

    // save cached content
    return messageBody;
  }

  static processEmoji(messageBody) {
    return wdtEmojiBundle.render(messageBody);
  }

  static processHTMLBody(htmlBody) {
    return  htmlBody.replace("<body xmlns=\"http://www.w3.org/1999/xhtml\">", "")
    .replace("</body>", "")
    .replace(/&amp;nbsp;/ig, " ")
    .replace(/&lt;/ig, "<")
    .replace(/&gt;/ig, ">")
    .replace(/&quot;/ig, "\"")
    .replace(/&amp;lt;/ig, "&lt;")
    .replace(/&amp;gt;/ig, "&gt;")
    .replace(/&amp;amp;quote;/g, "&quote;")
    .replace(/&amp;amp;trade;/g, "&trade;")
    .replace(/&amp;amp;copy;/g, "&copy;")
    .replace(/&amp;amp;/ig, "&amp;");
  }

  static processSpecialChars(messageBody) {
    return  messageBody
    .replace(/&amp;amp/g, "&amp;amp;amp")
    .replace(/&amp;lt/g, "&amp;amp;lt")
    .replace(/&amp;copy/g, "&amp;amp;copy")
    .replace(/&amp;trade/g, "&amp;amp;trade")
    .replace(/&amp;quote/g, "&amp;amp;quote")
    .replace(/&amp;gt/g, "&amp;amp;gt")
    .replace(/&amp;/g, "&");
  }

  static isOnIpad() {
    return /iPad/i
      .test(navigator.userAgent);
  }

  static isNullOrUndefined(value) {
    return (value === null || value === undefined);
  }

  static getRandomAvatarColor() {
    const colors = this.getAvatarbgColors();
    const random = Math.floor(Math.random() * colors.length);
    return colors[random];
  }

  static getAvatarbgColors() {
    return [
      "#F44336",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B"
    ];
  }
}
