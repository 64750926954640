
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="general_settings_dialog commander-dialog">
  <div class="commander__dialog-header mobile_header">
    <div>
      <button id="mobile_sidebar_legal_notice_backbtn" mat-button (click)="close()">
        <mat-icon class="disable-select">arrow_back</mat-icon>
      </button>
    </div>
    <div id="mobile_sidebar_header_header" class="header_lbl disable-select">
      {{ 'GENERAL_SETTINGS' | translate }}
    </div>
    <div class="submit">
        <button id="mobile_sidebar_legal_notice_backbtn" mat-button (click)="updateSettings()">
          <mat-icon class="disable-select">check</mat-icon>
        </button>
    </div>
  </div>
  <div class="commander__dialog__header desktop_view">
    <div class="header_title disable-select">
      {{ 'GENERAL_SETTINGS' | translate }}
    </div>
  </div>
  <div class="commander__dialog__body settings-form">
    <div class="language_select disable-select">
      <mat-form-field>
        <mat-select panelClass="dialog-mat-selection" placeholder="{{'LANGUAGE'|translate}}"
          [(ngModel)]="selectedLanguage" name="language">
          <mat-option *ngFor="let language of languageNames" [value]="language.value">
            {{language.languageKey | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="server_url" *ngIf="isCordovaOrElectron">
      <mat-form-field>
        <input matInput placeholder="{{'SERVER_URL'|translate}}" [(ngModel)]="serverURL">
      </mat-form-field>
    </div>
  </div>
  <div class="commander__dialog__footer">
    <button mat-button class="desktop_view disable-select" (click)="close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button mat-button class="desktop_view disable-select" (click)="updateSettings()">
      {{ 'SAVE' | translate }}
    </button>
  </div>
</div>