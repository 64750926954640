/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { SearchItem } from "../common/models/mail-models/search-item";
import { CommanderConstants } from "../common/utils/commander.constants";
import { BehaviorSubject, Subject } from "rxjs";
import { getContacts, getIsLoggedIn, getUserProfile, RootState } from "../reducers";
import { Store, select } from "@ngrx/store";
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import { AppRepository } from "../repositories/app.repository";
import { Broadcaster } from "../common/providers/broadcaster.service";
import * as moment from "moment";
import { Moment } from "moment";
import { ConfigService } from "../config.service";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormControl } from "@angular/forms";
// import * as _ from "lodash";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import { FeedRequest } from "../common/models/feed-request.model";
import { MessagingService } from "../../../service/messaging.service";

import { ElectronService } from "src/app/services/electron.service";
import { environment } from "src/environments/environment";
import { getLastPhotoUpdateByEmail, getOnlineStatus } from "src/app/reducers";
import { AppModel } from "../common/models/app.model";

@Component({
  selector: "vp-document-list",
  templateUrl: "./document-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnInit, OnDestroy {
  @Input() isSearchView: boolean;
  @Input() keyword: string;
  availableApps: AppModel[] = [];
  totalResults$ = new BehaviorSubject<number>(0);
  isLoggedIn: boolean;
  documents: SearchItem[] = [];
  appsList: {[app: string]: SearchItem[]} = {};
  apps = [];
  tags = [];
  tagsText: string = "Tags";
  allApplicationText: string = "All Application";
  orderByOptions = [
    {
      label: "New First",
      value: "created_dt desc"
    },
    {
      label: "Old First",
      value: "created_dt asc"
    }
  ];
  groupByOptions = [
    {
      label: "None",
      value: "none"
    },
    {
      label: "Application",
      value: "application"
    },
    {
      label: "User",
      value: "user"
    }
  ];
  availableAppsOptions = [];
  start = 0;
  orderBy = "created_dt desc";
  sortBy = "created_dt";
  sortOrder = "desc";
  groupBy = "none";
  tagss = [];
  searchParams: any = {
    q: "*:*",
    sort: this.orderBy.replace(" ", "%20"),
    start: this.start,
    limit: CommanderConstants.RESULTS_PER_PAGE
  };
  viewBy = "detail";
  isLoading: boolean;
  unreadOnly: boolean;
  isFavorite: boolean;
  activeApps: string[] = [];
  toggleStatus = {};
  searchSubscription$: any;
  users: string[] = [];
  usersList: any = {};
  showFilters = false;
  maxDate: any;
  minDate: any;
  selected: {startDate: Moment, endDate: Moment};
  inlineDateTime;
  lang = "en";
  orderByText: string = "New First";
  groupByText: string = "Group:None";
  showDateRange: boolean;
  private isAlive$ = new Subject<boolean>();
  totalPerPage: number = 20;
  totalPerPageOptions = [5, 10, 20];
  // totalPerPageOptions = [{label: '5 posts', value: 5},{label: '10 posts', value: 10},{label: '20 posts', value: 20}];
  createdAtOrder = [{label: "Newest first", value: "created_dt desc"}, {label: "Oldest first", value: "created_dt asc"}];
  totalResults: number;
  hideAllComments: boolean = false;
  usersFilter = new UntypedFormControl([-1]);
  authorsFilter = new UntypedFormControl([-1]);
  allUsersSelected = true;
  allAuthorsSelected = true;
  contacts: any[] = [];
  innerWidth: any;
  newMessageCount = 0;
  selectedUsers: string = "Users";
  selectedAuthors: string = "Authors";
  talkFeeds: any[] = [];
  mailFeeds: any[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private config: ConfigService,
    private translate: TranslateService, private messagingService: MessagingService,
    private appRepository: AppRepository, private store: Store<RootState>,
    private electronService: ElectronService) {
      this.maxDate = moment().add(2,  "weeks");
      this.minDate = moment().subtract(3, "days");
      this.appRepository.getLanguage().pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
        this.lang = lang;
        this.setShowFilters(false);
      });
      this.appRepository.getTotalPerPage().pipe(takeUntil(this.isAlive$)).subscribe((total: number) => {
        this.totalPerPage = total;
        this.searchParams.limit = total;
        this.changeDetectorRef.markForCheck();
      });
      this.appRepository.getDateRange().pipe(takeUntil(this.isAlive$)).subscribe((dateRange) => {
        this.selected = dateRange;
        this.changeDetectorRef.markForCheck();
      });
      this.appRepository.getShowFilters().pipe(takeUntil(this.isAlive$)).subscribe((value: boolean) => {
        this.showFilters = value;
        this.changeDetectorRef.markForCheck();
      });

    this.store.select(getContacts).pipe(takeUntil(this.isAlive$)).subscribe(contacts => {
    
      // this.contacts = orderBy(contacts, ["name"] , ["asc"]).map( p => ({name: p.name, value: p.defaultMail}));
      const users = orderBy(contacts.map((v: any) => {
        // const hash = this.service.buildHash(v.bare);
        // `${this.avatarServiceUrl}/${hash}.jpg`
        return {label: v.name, value: v.defaultMail
          , imgSrc: this.buildAvatarURL(v),
        fallBackInfo: {
          // bgColor: Utils.getAvatarBackground(Utils.getAvatarTextFromEmail(v.bare)),
          // text: Utils.getAvatarTextFromEmail(v.bare)
        }
      };
      }), ["label"], ["asc"]);
      this.contacts = users;
      this.changeDetectorRef.markForCheck();
    });
  }

  @HostBinding("class") get filterClass () { return this.showFilters ? "show-filters-bar" : ""; }

  ngOnInit() {
    this.appRepository.getIsLoggedIn().pipe(takeUntil(this.isAlive$)).subscribe(data => {
      this.isLoggedIn = data;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getViewBy().pipe(takeUntil(this.isAlive$)).subscribe(val => {
    
      if (!!this.isSearchView) {
        this.viewBy = "condense";
      } else {
        this.viewBy = val;
      }
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getGroupBy().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      this.groupBy = val;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getGlobalTags().subscribe(tags => {
      this.tags = tags.map(tag => ({
        label: tag.name,
        value: tag.name,
      }));
      this.changeDetectorRef.markForCheck();
    });

    this.appRepository.getFederatedApps().subscribe(apps => {
      this.availableApps = apps;
      this.availableAppsOptions = apps.map(app => ({
        label: app.appName,
        value: app.appKey,
      }));
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getTotalPerPage().pipe(takeUntil(this.isAlive$)).subscribe((val: number) => {
      this.totalPerPage = val;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getOrderBy().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      this.orderBy = val;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getUnreadOnly().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      this.unreadOnly = val;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getHideAllComments().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      this.hideAllComments = val;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getIsAppLoading().pipe(takeUntil(this.isAlive$)).subscribe(val => {
    
      this.isLoading = val;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getActiveApps().pipe(
      takeUntil(this.isAlive$), debounceTime(800), distinctUntilChanged()
    ).subscribe(apps => {
      
      this.activeApps = apps;
      this.changeDetectorRef.markForCheck();
      this.refreshData();
    });
    this.broadcaster.on<any>("triggerSearch").pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.refreshData();
    });

    this.broadcaster.on<any>("closeSearch").pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.keyword = "";
      this.isSearchView = false;
      this.refreshData();
    });

    this.broadcaster.on<any>("changestarvalue").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.documents = this.documents.map(doc => {
        if (doc.id === value.docId) {
          doc.metadata["isStarred"] = value.value;
        }
        return doc;
      });
    });

    this.broadcaster.on<any>("deleteDocument").pipe(takeUntil(this.isAlive$)).subscribe((document: SearchItem) => {
      
      this.documents = [...this.documents].filter(doc => !(doc.id === document.id && doc.type === document.type));
      this.changeDetectorRef.markForCheck();
      console.log("++++++==============================================================>");
      console.log(this.documents);
    });

    this.usersFilter.valueChanges.pipe(takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.allUsersSelected)) {
          this.usersFilter.setValue([-1]);
          this.allUsersSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.allUsersSelected = false;
          this.usersFilter.setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.authorsFilter.valueChanges.pipe(takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.allAuthorsSelected)) {
          this.authorsFilter.setValue([-1]);
          this.allAuthorsSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.allAuthorsSelected = false;
          this.authorsFilter.setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.innerWidth = window.innerWidth;

    if (innerWidth < 600) {
      this.setViewBy("condense");
    }
    
    this.messagingService.currentMessage.subscribe(
      message => {
        if (!!message) {
          this.newMessageCount++;
          this.changeDetectorRef.markForCheck();
        }
        
      }
    );
    this.innerWidth = window.innerWidth;

    if (innerWidth < 600) {
      this.setViewBy("condense");
    }
   
    this.messagingService.currentMessage.subscribe(
      message => {
        if (!!message) {
          this.newMessageCount++;
          this.changeDetectorRef.markForCheck();
        }
     
      }
    );
  }


  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (innerWidth < 600) {
      this.setViewBy("condense");
    }
  }

  toggleApp(app) {
    this.allApplicationText = app;
    this.appRepository.toggleActiveApp(app);
  }

  datesUpdated(range) {
    this.selected = {...range};
    this.appRepository.setDateRange(range);
    this.refreshData();
  }

  openApplicationSmart() {
    document.getElementById("application-smart-filter")
      .querySelector("mat-form-field")
      .querySelector(".mat-form-field-infix")
      .setAttribute("id", "application-smart-filter-trigger");
    document.getElementById("application-smart-filter-trigger").click();
  }

  filterFavorite() {
    this.isFavorite = !this.isFavorite;
    this.refreshData();
  }

  filterTags() {
    document.getElementById("tags-smart-filter")
    .querySelector("mat-form-field")
    .querySelector(".mat-form-field-infix")
    .setAttribute("id", "tags-smart-filter-trigger");
    document.getElementById("tags-smart-filter-trigger").click();
  }

  applyUsers(event: any) {
  
    this.refreshData();
  }

  refreshData() {
    this.start = 0;
    this.newMessageCount = 0;
    this.documents = [];
    this.appsList = {};
    this.usersList = {};
    const supportedApps = ["mail", "task", "talk"];
    const selectedApps = this.activeApps.filter(v => supportedApps.includes(v));
    if (selectedApps.length > 0) {
      this.searchDocs();
    } else {
      this.documents = [];
    }
  }

  private processDocs(docs) {
    return docs.map(doc => {
      doc.metadata = {};
      if (doc.flags && doc.flags.indexOf("u") !== -1) {
        doc.metadata.isUnread = true;
      }
      if (doc.flags && doc.flags.indexOf("a") !== -1) {
        doc.metadata.hasAttachment = true;
      }
      if (doc.flags && doc.flags.indexOf("f") !== -1) {
        doc.metadata.isStarred = true;
      }
      return doc;
    });
  }

  mergeDocs( existingDocs: SearchItem[], newDocs: SearchItem[]) {
    const updatedDocs = [...existingDocs];
    newDocs.forEach(doc => {
      if (doc.type !== "talk") {
        updatedDocs.push(doc);
      } else {
        if (!!doc.title && existingDocs.filter(e => e.title === doc.title).length === 0) {
          updatedDocs.push(doc);
        } else if (!!doc.from && existingDocs.filter(e => (e.type === "talk" && e.title === doc.title)).length === 0) {
          updatedDocs.push(doc);
        }
      }
    });

    return updatedDocs;
  }

  searchDocs() {
    this.store.select(getIsLoggedIn).pipe(filter(v => !!v), take(1)).subscribe(v => {
      this.appRepository.getSearchString().pipe(take(1)).subscribe(val => {
        this.keyword = val;
      });
      let currentUserEmail = "";
      this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(c => {
        currentUserEmail = c.email;
      });

      this.appRepository.getTotalPerPage().pipe(take(1)).subscribe((total: number) => {
        
        this.totalPerPage = total;
      });

      let advancedFilters;
      this.appRepository.getFilters().pipe(take(1)).subscribe(v => {
        
        if (v.filters) {
          advancedFilters = v.filters;
        }
      });

     
      this.changeDetectorRef.markForCheck();
      if (this.searchSubscription$) {
        this.searchSubscription$.unsubscribe();
      }

      let dateRange = "";
      if (this.selected && this.selected.startDate && this.selected.endDate) {
        const startDate = this.selected.startDate.toISOString();
        const endDate = startDate === this.selected.endDate.toISOString() ?
         new Date(new Date(startDate).setHours(23, 59, 59, 999)).toISOString() :
         this.selected.endDate.toISOString();
        
        dateRange = `${startDate} TO ${endDate}`;
      }
      let tag = "";
      // TODO implement tags once it is implemented in Solr

      let groupBy: {field: string, groupLimit: number};
      let rows = this.totalPerPage;
      if (this.groupBy === "application") {
        groupBy = {
          field: "type_s",
          groupLimit: this.totalPerPage,
        };
        rows = 0;
      } else if (this.groupBy === "user") {
        groupBy = {
          field: "from_s",
          groupLimit: this.totalPerPage,
        };
        rows = 0;
      }

      const filters = [];

      filters.push("-talk_type_s:call"); // To Filter out call type messages from talk
      if (!!dateRange) {
        filters.push(`created_dt:[${dateRange}]`);
      }

      if (!isEmpty(this.tagss)) {
        const tagsFilter = this.tagss.map(
          tag => `${tag}`
        ).join(" OR ");
        filters.push(`tags_ss:(${tagsFilter})`);
      }

      if (this.isFavorite) {
        filters.push(`is_favorite:${this.isFavorite}`);
      }

      if (this.unreadOnly) {
        filters.push(`mail_unread_b:true OR talk_unread_user_ss:${currentUserEmail}`);
      }

      if (Array.isArray(this.usersFilter.value) && this.usersFilter.value.toString() !== "-1") {
        const usersFilters = this.usersFilter.value.map(
          user => `(from_s:${user.value} OR to_ss:${user.value})`
        ).join(" OR ");
        filters.push(usersFilters);
      }

      if (Array.isArray(this.authorsFilter.value) && this.authorsFilter.value.toString() !== "-1") {
        const authorsFilter = this.authorsFilter.value.map(
          user => `(from_s:${user.value} OR to_ss:${user.value})`
        ).join(" OR ");
        filters.push(authorsFilter);
      }

      if (!!this.isSearchView && !!advancedFilters) {
        
        const advFilters: string[] = [];

        if (!isEmpty(advancedFilters.talkFilters)) {
          const filters = advancedFilters.talkFilters;
          const talkFilters = ["type_s:talk"];
          if (filters.insideAttachments) {
            talkFilters.push("talk_attachment_s:[* TO *]");
          }
          // if (filters.insideTickets) {
          //   // TODO
          //   talkFilters.push("talk_attachment_s:true");
          // }
          if (filters.sender) {
            const senderFilters = filters.sender.map(
              user => `(from_s:${user})`
            ).join(" OR ");
            talkFilters.push(`(${senderFilters})`);
          }
          advFilters.push(`(${talkFilters.join(" AND ")})`);
        }

        if (!isEmpty(advancedFilters.taskFilters)) {
          const filters = advancedFilters.taskFilters;
          const taskFilters = ["(type_s:issue AND issue_type:task)"];

          if (filters.assignedTo) {
            const assignedToFilters = filters.assignedTo.map(
              user => `(to_ss:${user})`
            ).join(" OR ");
            taskFilters.push(`(${assignedToFilters})`);
          }

          // if (filters.project) {
          //   const projectFilters = filters.project.map(
          //     // TODO
          //     project => `(to_ss:${project})`
          //   ).join(" OR ");
          //   taskFilters.push(`(${projectFilters})`);
          // }

          if (filters.priority) {
            const priorityFilters = filters.priority.map(
              priority => `(priority_s:"${priority}")`
            ).join(" OR ");
            taskFilters.push(`(${priorityFilters})`);
          }

          if (filters.status) {
            const statusFilters = filters.status.map(
              status => `(status_s:"${status}")`
            ).join(" OR ");
            taskFilters.push(`(${statusFilters})`);
          }
          advFilters.push(`(${taskFilters.join(" AND ")})`);
        }

        if (!isEmpty(advancedFilters.mailFilters)) {
          const filters = advancedFilters.mailFilters;
          const mailFilters = ["type_s:mail"];

          if (filters.sentTo) {
            const sentToFilters = filters.sentTo.map(
              user => `(to_ss:${user})`
            ).join(" OR ");
            mailFilters.push(`(${sentToFilters})`);
          }

          if (filters.receivedFrom) {
            const receivedFromFilters = filters.receivedFrom.map(
              user => `(from_s:${user})`
            ).join(" OR ");
            mailFilters.push(`(${receivedFromFilters})`);
          }

          // if (filters.attachmentType) {
          //   const attachmentTypeFilters = filters.attachmentType.map(
          //     // TODO
          //     attachmentType => `(mail_attachments_s:${attachmentType})`
          //   ).join(" OR ");
          //   mailFilters.push(`(${attachmentTypeFilters})`);
          // }

          if (filters.folder) {
            const folderFilters = filters.folder.map(
              folder => `(mail_folder_id_i:${folder})`
            ).join(" OR ");
            mailFilters.push(`(${folderFilters})`);
          }

          if (filters.status) {
            const statusFilters = [];

            filters.status.forEach(status => {
              if (status === "READ") {
                statusFilters.push("(mail_unread_b:false)");
              }

              if (status === "UNREAD") {
                statusFilters.push("(mail_unread_b:true)");
              }

              if (status === "FLAGGED") {
                statusFilters.push("mail_flag_s:[* TO *]");
              }

              if (status === "NOT_FLAGGED") {
                statusFilters.push("-mail_flag_s:[* TO *]");
              }

              if (status === "SENT_BY_ME") {
                statusFilters.push(`from_s:${currentUserEmail}`);
              }

              if (status === "RECEIVED_BY_ME") {
                statusFilters.push(`-from_s:${currentUserEmail}`);
              }
            });
            mailFilters.push(`(${statusFilters.join(" OR ")})`);
          }
          advFilters.push(`(${mailFilters.join(" AND ")})`);
        }
        filters.push(advFilters.join(" OR "));
      }

      if (this.activeApps.length > 0) {
      
        const appFilters = this.activeApps.map( app => {
          if (app === "task") {
            return `(type_s:issue AND issue_type:${app})`;
          }
          return `type_s:${app}`;
        }).join(" OR ");
        filters.push(appFilters);
      }

      const body: FeedRequest = {
        query: !!this.keyword ? this.keyword : "*:*",
        sort: !!this.orderBy ? this.orderBy : "created_dt desc, id desc",
        start: this.start,
        filters: filters,
      };

      if (!!groupBy) {
        body["groupBy"] = groupBy;
      }

      if (!!rows) {
        body["rows"] = rows;
      }
      this.searchSubscription$ = this.appRepository.searchDocs(body).subscribe(res => {
       
        if (this.groupBy === "none") {
          this.documents = this.mergeDocs(this.documents, res.docs);
          this.processDocs(this.documents);
          this.catFeeds(this.documents);
        } else if (this.groupBy === "application") {
          this.apps = Object.keys(res.groups);
          for (let app of this.apps) {
            let docs = this.appsList[app] || [];
            docs = [...docs, ...res.groups[app]];
            this.appsList[app] = docs;
            this.catFeeds(docs);

          }
        } else if (this.groupBy === "user") {
          this.users = Object.keys(res.groups);
          for (let user of this.users) {
            let docs = this.usersList[user] || [];
            docs = [...docs, ...res.groups[user]];
            this.usersList[user] = docs;
            this.catFeeds(docs);
          }
        }
        this.totalResults$.next(res.numFound);
        this.totalResults = res.numFound;
        this.start = res.start;
        this.newMessageCount = 0;
       
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  updateUnreadStatus() {
    
    this.unreadOnly = !this.unreadOnly;
    this.changeDetectorRef.markForCheck();
    this.appRepository.setUnreadOnly(this.unreadOnly);
    this.refreshData();
  }

  setViewBy(val: string) {
    if (val === "table") {
      return this.appRepository.underDevelopment();
    }
    this.viewBy = val;
    this.appRepository.setViewBy(val);
  }

  setOrderBy(val: any[]) {
    let orderByVal = val.toString();
    if (orderByVal === "created_dt desc") {
      this.orderByText = "New First";
    } else {
      this.orderByText = "Old First";
    }
    this.orderBy = orderByVal;
    this.appRepository.setOrderBy(orderByVal);
    this.refreshData();
  }

  setGroupBy(val: any[]) {
    let groupByVal = val.toString();
    this.groupByText = "Group:" + groupByVal;
    this.groupBy = groupByVal;
    this.appRepository.setGroupBy(groupByVal);
    this.refreshData();
  }

  setTags(val) {
    this.tagsText = val.toString();
    this.tagss = val;
    this.appRepository.setTags(val);
    this.refreshData();
  }

  onUserScroll(event) {
    const documentList = event.target;
    if (documentList.id === "document-list") {
      const isAtBottom = documentList.scrollTop + documentList.offsetHeight + 5 > documentList.scrollHeight;
      if (isAtBottom && this.totalResults$.value > this.documents.length && !this.isLoading) {
        this.start += this.totalPerPage;
        if (this.groupBy === "none") {
          this.searchDocs();
        }
      }
    }

    console.log("++++++==============================================================>");
    console.log(this.documents);
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  setTotalPosts(total) {
    this.appRepository.setTotalPerPage(total);
    this.refreshData();
  }

  setShowFilters(value) {
    this.appRepository.setShowFilters(value);
  }

  toggleHideComments() {
    this.hideAllComments = !this.hideAllComments;
    this.changeDetectorRef.markForCheck();
    this.appRepository.setHideAllComments(this.hideAllComments);
 
  }

  clearFilters() {
    const filters = {
      groupBy: "none",
      viewBy: "detail",
      orderBy: "created_dt desc",
      selectedTag: "",
      startDate: "",
      endDate: "",
      showOnlyUnread: true,
      starredOnly: false,
      hasAttachments: false,
      hideAllComments: false,
      totalPerPage: 20,
    };
    this.resetDateFilter();
    this.usersFilter.setValue([-1]);
    if ( document.getElementsByClassName("clickable")?.length ) {
      document.getElementsByClassName("clickable")[0].
      setAttribute("id", "clear-user-filter-trigger");
      document.getElementById("clear-user-filter-trigger").click();
    }
    let apps: string[] = [];
    this.appRepository.getFederatedApps().pipe(take(1)).subscribe(allApps => {
      apps = allApps.map(app => app.appKey);
    });
    this.appRepository.setViewBy(filters.viewBy);
    this.appRepository.setGroupBy(filters.groupBy);
    this.appRepository.setOrderBy(filters.orderBy);
    this.appRepository.setTotalPerPage(filters.totalPerPage);
    this.appRepository.setUnreadOnly(filters.showOnlyUnread);
    this.appRepository.setHideAllComments(filters.hideAllComments);
    this.appRepository.setActiveApps(apps);
    this.appRepository.setFilters({apps, filters});
    this.refreshData();
  }

  refreshFeed() {
    let startDate = this.selected?.startDate;
    let endDate = moment();
    if (moment(this.selected?.endDate ? this.selected?.endDate : moment()).isAfter(moment().subtract(1, "days").endOf("day"))) {
      this.appRepository.setDateRange(
        {
          startDate: startDate, 
          endDate: startDate ? endDate : null
        }
      );
      this.refreshData();
    }
  }

  resetDateFilter() {
    // const startDate = moment().subtract(30, "days").set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    // const endDate = moment();
    this.appRepository.setDateRange(null);
    this.refreshData();
  }

  resetUserFilter() {
    this.usersFilter.setValue([-1]);
    this.selectedUsers = "Users";
    if (document.getElementsByClassName("clickable")?.length) {
      document.getElementsByClassName("clickable")[0].
      setAttribute("id", "clear-user-filter-trigger");
      document.getElementById("clear-user-filter-trigger").click();
    }
    this.refreshData();
  }

  applyAllUserFilters($event) {
    console.log($event);
    let usersSelected: any[] = $event;
    let usersList: any[] = [];
    let  userObj: any [] = [];
    usersSelected.forEach(user => {
     let userIndex = this.contacts.findIndex(item => item.value === user);
     if (userIndex > -1) {
      usersList.push(this.contacts[userIndex].label);
      userObj.push(this.contacts[userIndex]);
     }
    });
    this.usersFilter.setValue(userObj);
    this.selectedUsers = usersList.length ? usersList.join(",") : "Users";
    this.refreshData();
    this.changeDetectorRef.markForCheck();
  }

  applyAllAuthorFilters($event) {
    console.log($event);
    let authorsSelected: any[] = $event;
    let authorsList: any[] = [];
    let  authorsObj: any [] = [];
    authorsSelected.forEach(user => {
     let userIndex = this.contacts.findIndex(item => item.value === user);
     if (userIndex > -1) {
      authorsList.push(this.contacts[userIndex].label);
      authorsObj.push(this.contacts[userIndex]);
     }
    });
    this.authorsFilter.setValue(authorsObj);
    this.selectedAuthors = authorsList.length ? authorsList.join(",") : "Authors";
    this.refreshData();
    this.changeDetectorRef.markForCheck();
  }

  resetAuthorsFilter() {
    this.authorsFilter.setValue([-1]);
    this.selectedAuthors = "Authors";
    if (document.getElementsByClassName("clickable")?.length) {
      document.getElementsByClassName("clickable")[0].
      setAttribute("id", "clear-author-filter-trigger");
      document.getElementById("clear-author-filter-trigger").click();
    }
    this.refreshData();
  }

  openUserFilter() {
    document.getElementById("user-smart-filter")
      .querySelector("mat-form-field")
      .querySelector(".mat-form-field-infix")
      .setAttribute("id", "user-smart-filter-trigger");
    document.getElementById("user-smart-filter-trigger").click();
  }

  openAuthorFilter() {
    document.getElementById("author-smart-filter")
      .querySelector("mat-form-field")
      .querySelector(".mat-form-field-infix")
      .setAttribute("id", "author-smart-filter-trigger");
    document.getElementById("author-smart-filter-trigger").click();
  }

  openOrderByFilter() {
    document.getElementById("order-by-smart-filter")
      .querySelector("mat-form-field")
      .querySelector(".mat-form-field-infix")
      .setAttribute("id", "order-by-smart-filter-trigger");
    document.getElementById("order-by-smart-filter-trigger").click();
  }

  openGroupByFilter() {
    document.getElementById("group-by-smart-filter")
      .querySelector("mat-form-field")
      .querySelector(".mat-form-field-infix")
      .setAttribute("id", "group-by-smart-filter-trigger");
    document.getElementById("group-by-smart-filter-trigger").click();
  }

   buildAvatarURL(user) {
    let isOnline = false;
    this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), take(1)).subscribe(v => {
      isOnline = v;
    });
    if (isOnline) {
      // const avatarVersion = this.avatarVersion();
      let avatarId = "";
      if (environment.isElectron) {
        avatarId = this.electronService.md5(user.defaultMail);
      } else {
        avatarId = md5(user.defaultMail);
      }
   

      if (this.config.get("avatarURL")) {
        // let avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg${avatarVersion}`;
        this.changeDetectorRef.markForCheck();
        let avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg`;
        return avatarURL;
      }
    }
  }

  catFeeds(feeds) {
    this.talkFeeds = feeds.filter(feed => feed.type === "talk");
    this.mailFeeds = feeds.filter(feed => feed.type === "mail");
  }

}
