
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="version_dialog" [style.backgroundImage]="backgroundImage">
  <div class="commander__dialog-header desktop_view">
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="commander__dialog-body commander__dialog-body--pad">
    <div class="content">
      <img alt="" class="app_icon disable-select" [src]="appLogo">
      <h6 class="app_version_name">
        <div class="logo-title">
          <span class="disable-select prefix_text">{{prefixBold}}</span>
          <span class="product_name disable-select">{{suffixNormal}}</span>
        </div>
      </h6>
      <span class="app_version disable-select">
        {{ 'VERSION_LBL' | translate }} {{ app_version }}
      </span>
      <p id="mobile_sidebar_about_version_changelog" class="change_log">
        <a class="open-new-window disable-select" href="{{changeLog}}" target="_blank">{{ 'CHANGELOG'
          | translate }}</a>
      </p>
    </div>
  </div>
</div>