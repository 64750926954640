/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { of, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppRepository } from "../repositories/app.repository";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { getOnlineStatus, getUserProfile, RootState } from "../reducers";
import { debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil } from "rxjs/operators";
// import * as _ from "lodash";
import trim from "lodash/trim";
import { CommonUtils } from "../common/utils/common-util";
import { Recipient } from "../common/models/vnctalk.model";
import { UserProfile } from "../common/models/mail-models";
// import { ChatPreviewComponent } from "../chat-preview/chat-preview.component";
import { Contact } from "../common/models/ldap-contact.model";

@Component({
  selector: "vp-start-chat",
  templateUrl: "./start-chat.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  searchControl: UntypedFormControl;
  contacts = [];
  isAppOnline: boolean;
  currentUser: UserProfile;
  private isAlive$ = new Subject<boolean>();
  searchedUsers: any = [];
  filteredRecipients: Recipient[] = [];
  constructor(private dialogRef: MatDialogRef<StartChatComponent>,
    public appRepository: AppRepository,
    private matDialog: MatDialog,
    private store: Store<RootState>,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.searchControl = new UntypedFormControl("", [Validators.required]);
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(profile => {
      this.currentUser = profile;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.loadAllConversations().subscribe(convs => {
      
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (document.getElementById("searchText") !== null) {
        document.getElementById("searchText").focus();
      }
    }, 1000);
    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
    this.searchControl.valueChanges
      .pipe(takeUntil(this.isAlive$)
        , debounceTime(500)
        , distinctUntilChanged()
        , switchMap((value: any, index: number): any => {
          if (value.length > 0) {
            return this.appRepository.searchLDAP(value);
          } else {
            return of([]);
          }
        }))
      .subscribe((contacts: any[]) => {
       
        this.searchedUsers = contacts.filter(u => u.email !== this.currentUser.defaultMail).map(u => {
          const recipient: Recipient = { target: u.email, title: u.name || u.email.split("@")[0], type: "user", ldapData: u.ldapData };
          return recipient;
        });
        this.getFilteredRecipients();
      });
    this.appRepository.loadAllConversationsFromStore().pipe(take(1)).subscribe(conversations => {
      
      const contacts: Recipient[] = conversations.filter(conv => conv.type === "chat" && !conv.Target.startsWith("broadcast-")).map(c => {
        const r: Recipient = {
          target: c.Target,
          title: c.Target.split("@")[0],
          type: "chat"
        };
        return r;
      });
      this.contacts = contacts;
      this.getFilteredRecipients();
      this.changeDetectorRef.markForCheck();
    });
  }

  getFilteredRecipients(): void {
    let recipients: Recipient[] = [];
    if (this.searchControl.value.length > 0) {
      let filteredGroupchat: Recipient[] = [];
      if (this.searchControl.value.trim().length > 1) {
        filteredGroupchat = this.contacts.filter(r => r.target.toLowerCase().indexOf(this.searchControl.value.trim().toLowerCase()) !== -1
          || r.title.toLowerCase().indexOf(this.searchControl.value.trim().toLowerCase()) !== -1);
        const ldapMail = this.searchedUsers.map(u => u.target);
        filteredGroupchat = filteredGroupchat.filter(c => ldapMail.indexOf(c.target) === -1);
        recipients = filteredGroupchat.concat(this.searchedUsers);
      }

    } else {
      recipients = this.contacts;
    }
    recipients.map(r => {
      let contact: Contact;
      this.appRepository.getContactByEmail(r.target).pipe(take(1)).subscribe(res => contact = res);
      if (!!contact) {
        r.title = contact.name;
      }
      return r;
    });
    
    this.filteredRecipients = recipients;
    this.changeDetectorRef.markForCheck();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    window.scrollTo(0, 0);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  handleEnterKeyPress() {
    let addr = trim(this.searchControl.value);
    if (CommonUtils.validateEmail(addr)) {
      
    }
  }

  async startConversation(target) {
    this.closeDialog();
    const { ChatPreviewComponent } = await import(
      /* webpackPrefetch: true */
      "../chat-preview/chat-preview.component"
      );
    const dialogRef = this.matDialog.open(ChatPreviewComponent, {
      width: "80%",
      height: "600px",
      autoFocus: true,
      panelClass: "commander__dialog",
      data: target
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      
    });
  }
}
