
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="search-top-bar">
  <div class="search-keyword" *ngIf="keyword">{{ keyword }}:</div>
  <div class="search-label">{{ 'SEARCH_RESULTS' | translate }} <span class="total-results">({{ totalResults }})</span> </div>
  <button mat-button (click)="$event.stopPropagation();refineSearch()">
    <span>{{'REFINE_SEARCH' | translate}}</span>
  </button>

  <div class="search-save">
    <button mat-button (click)="$event.stopPropagation();openSearchNameDialogue()">
      <span>{{'SAVE_SEARCH' | translate}}</span>
    </button>

    <div class="search-name-dialogue" *ngIf="showSearchNameDialogue">
      <input matInput name="search" [placeholder]="'ENTER_SAVED_SEARCH_NAME' | translate"
             [formControl]="searchNameCtrl" class="search__name">
      <button mat-button class="clear-button"
              (click)="$event.stopPropagation();closeSearchNameDialogue()">
        <span>{{'CANCEL' | translate}}</span>
      </button>
      <button mat-button class="clear-button"
              (click)="$event.stopPropagation();saveSearch()">
        <span>{{'SAVE' | translate}}</span>
      </button>
    </div>
  </div>
  <button mat-icon-button (click)="$event.stopPropagation();closeSearch()">
    <mat-icon>close</mat-icon>
    <span>{{'EXIT' | translate}}</span>
  </button>
</div>
<vp-document-list #documentList [isSearchView]="true" [keyword]="keyword"></vp-document-list>
