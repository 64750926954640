
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div class="commander_header">
    <vnc-top-bar recentSearchLabel="{{ 'RECENT_SEARCHES'| translate }}" noRecentSearchLabel="{{ 'NO_RECENT_SEARCHES' | translate }}" [enableAutoComplete]="false">
      <vnc-top-bar-apps-button appswitch="true" (click)="switchAppMenuOption()"></vnc-top-bar-apps-button>
      <span vnc-top-bar-title class="app-title ">
        <ng-container>
          <div>
            <img class="vnc_logo" src="/assets/images/logo-commander.svg" style="margin-right: 16px;">
          </div>
          <!-- <div class="logo-title"><span class="vnc">VNC</span><span class="mail">commander</span></div> -->
        </ng-container>
      </span>
      <vnc-icons-notification vnc-top-bar-icon-button [size]="1" [isActive]="false" (click)="comingSoon()" (clicked)="openNotificationDialog()"></vnc-icons-notification>
      <vnc-icons-panel vnc-top-bar-icon-button [size]="1" (clicked)="toggleSidebar()" [isActive]="isSidebarExpanded"
        (click)="toggleRightSidebar()" [class.is-expanded]="isSidebarExpanded"></vnc-icons-panel>
      <vnc-top-bar-user-button *ngIf="!userImageUrl" [defaultAvatar]="defaultAvatar" mat-button [matMenuTriggerFor]="headerMenu"></vnc-top-bar-user-button>
      <vnc-top-bar-user-button *ngIf="userImageUrl" [defaultAvatar]="defaultAvatar" [userImage]="avatarURL" mat-button [matMenuTriggerFor]="headerMenu"></vnc-top-bar-user-button> 
    </vnc-top-bar>
  </div>

<mat-menu #headerMenu="matMenu" xPosition="before" class="header-menu">
  <button mat-menu-item (click)="profile()">
    <mat-icon>person_outline</mat-icon>
    <span>{{ 'PROFILE' | translate }}</span>
  </button>
  <button mat-menu-item (click)="generalSettings()">
    <i class="material-icons-outlined">
      settings
    </i>
    <span>{{ 'SETTINGS' |translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="helpMenu">
    <mat-icon>help_outline</mat-icon>
    <span class="disable-select">{{ 'HELP' |translate }}</span>
  </button>
  <button [attr.id]="'logoutMenuBtn'" mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'LOGOUT' | translate }}</span>
  </button>
</mat-menu>

<div class="user-menu" *ngIf="showUserMenu">
  <div class="user-menu__mask" id="closeMainMenu" (click)="showUserMenu = false;">
  </div>
  <div class="user-menu__profile" (click)="profile()" id="mobile-user-menu-profile" [style.backgroundImage]="profileBackground">
    <div class="user-menu__profile__avatar" id="mobile-user-menu-profile-avtar">
      <vp-avatar [type]="'profile'" [user]="currentUser"> </vp-avatar>
    </div>
    <h5 class="screen-name-mobile" id="mobile-screen-user-email">{{currentUser?.email}}
    </h5>
  </div>
  <div class="user-menu__options" [class.is-browser]="!isCordovaApp" (click)="showUserMenu = false;">

    <mat-list class="option-list">
      <mat-list-item [attr.id]="'appSwitchBtn'">
        <mat-icon (click)="openAppSwitcherDialog()">apps</mat-icon>
        <span class="option-name" (click)="openAppSwitcherDialog()">{{ 'APPS_TITLE' | translate }}</span>
      </mat-list-item>
      <mat-list-item id="mobile-sidebar-commander" (click)="underDevelopment()">
        <mat-icon >explore</mat-icon>
        <span class="option-name">{{ 'COMMANDER' | translate }}</span>
      </mat-list-item>
      <mat-list-item id="mobile-sidebar-tag-list" (click) = "openMobileTagsDialog()">
        <mat-icon>local_offer</mat-icon>
        <span class="option-name">{{ 'TAGS' | translate }}</span>
      </mat-list-item>
      <mat-list-item id="savedSearches" (click) = "openMobileSearchesDialog()">
        <mat-icon >search</mat-icon>
        <span class="option-name">{{'SAVED_SEARCH_LBL' | translate}}</span>
      </mat-list-item>
      <!-- <mat-list-item id="briefcaseMenu" (click)="openBriefcase()">
        <mat-icon >work</mat-icon>
        <span class="option-name">{{ 'BRIEFCASE_TITLE' | translate }}</span>
      </mat-list-item> -->
      <!-- <mat-list-item id="mailPreferences" (click) = "openPreferenceDialog()">
        <mat-icon>settings_applications</mat-icon>
        <span class="option-name">{{ 'PREFERENCES_LBL' | translate }}</span>
      </mat-list-item> -->
      <mat-list-item id="settingsMenuBtn" (click)="openMobileSettingsDialog()">
          <mat-icon>settings</mat-icon>
          <span class="option-name">{{ 'SETTINGS' | translate }}</span>
      </mat-list-item>
      <!-- <mat-list-item id="aboutsMenu" (click)="underDevelopment()">
          <mat-icon >info_outline</mat-icon>
          <span class="option-name">{{ 'ABOUT_TITLE' | translate }}</span>
      </mat-list-item> -->
      <!-- <span>
        <mat-list-item id="changePassMenu" (click)="changePassword()">
              <mat-icon>lock</mat-icon>
              <span class="option-name">{{ 'CHANGE_PASSWORD_LBL' | translate }}</span>
        </mat-list-item>
      </span> -->

      <mat-list-item [attr.id]="'logoutMenuBtn'" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span class="option-name">{{ 'LOGOUT_TITLE' | translate }}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>
<vp-app-switcher (closeSwitch)="switchApp = false" [switchApp]="switchApp"></vp-app-switcher>

<mat-menu #headerSettingsMenu="matMenu" class="commander_desktop_settings_menu">
  <button mat-menu-item [matMenuTriggerFor]="generalSettingsMenu">
    <mat-icon>settings</mat-icon>
    <span class="disable-select">{{ 'SETTINGS' | translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="preferenceSettingsMenu">
    <mat-icon>settings_applications</mat-icon>
    <span class="disable-select">{{ 'PREFERENCES' | translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="helpMenu">
    <mat-icon>help_outline</mat-icon>
    <span class="disable-select">{{ 'HELP' |translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="legalNoticeMenu">
    <svg viewBox="0 0 24 24">
      <path fill="#888888"
        d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
    </svg>
    <span class="disable-select">{{ 'LEGAL_NOTICE' |translate }}</span>
  </button>
</mat-menu>

<mat-menu #generalSettingsMenu="matMenu" class="commander_desktop_settings_menu">
  <button mat-menu-item (click)="generalSettings()">
    <mat-icon>settings</mat-icon>
    <span>{{ 'GENERAL_SETTINGS' |translate }}</span>
  </button>
  <button mat-menu-item (click)="underDevelopment()">
    <mat-icon>brush</mat-icon>
    <span>{{ 'APPEARANCE' |translate }}</span>
  </button>
</mat-menu>
<mat-menu #preferenceSettingsMenu="matMenu" class="commander_desktop_settings_menu">
  <button mat-menu-item (click)="underDevelopment()">
    <mat-icon>settings_applications</mat-icon>
    <span>{{ 'MAIN_PREFERENCES' |translate }}</span>
  </button>
  <button mat-menu-item (click)="underDevelopment()">
    <mat-icon>account_box</mat-icon>
    <span>{{ 'PROFILE_PREFERENCES' |translate }}</span>
  </button>
  <button mat-menu-item (click)="underDevelopment()">
    <mat-icon>notifications</mat-icon>
    <span>{{ 'NOTIFICATION_PREFERENCES' |translate }}</span>
  </button>
</mat-menu>
<mat-menu #helpMenu="matMenu" class="commander_desktop_settings_menu header-menu subenu-header">
  <button mat-menu-item (click)="versionDialog()">
    <mat-icon>info_outline</mat-icon>
    <span>{{ 'APP_INFO' |translate }}</span>
  </button>
  <a href="{{userManual}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <i class="material-icons-outlined">
        insert_drive_file
      </i>
      <span>{{ 'USER_MANUAL' | translate }}</span>
    </button>
  </a>
  <a href="{{serviceDeskURL}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <i class="material-icons-outlined">
        headset_mic
      </i>
      <span>{{ serviceDeskMenuItemName }}</span>
    </button>
  </a>
  <a href="{{faqURL}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>help_outline</mat-icon>
      <span>{{ 'FAQ' |translate }}</span>
    </button>
  </a>
  <a href="{{termsOfUse}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <i class="material-icons-outlined">
        insert_drive_file
      </i>
      <span>{{ 'TERMS_OF_USE' | translate }}</span>
    </button>
  </a>
  <a href="{{dataPrivacy}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>lock_outline</mat-icon>
      <span>{{ 'DATA_PRIVACY' |translate }}</span>
    </button>
  </a>
</mat-menu>

<mat-menu #legalNoticeMenu="matMenu" class="commander_desktop_settings_menu">
  <a href="{{termsOfUse}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>assignment</mat-icon>
      <span>{{ 'TERMS_OF_USE' | translate }}</span>
    </button>
  </a>
  <a href="{{dataPrivacy}}" target="_blank" class="menu-link">
    <button mat-menu-item>
      <mat-icon>lock</mat-icon>
      <span>{{ 'DATA_PRIVACY' |translate }}</span>
    </button>
  </a>
</mat-menu>
