/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, NgModule } from "@angular/core";
import { CanLoad, Route, RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment.dev";
import { ConfigService } from "./config.service";
import { Store } from "@ngrx/store";
import { AuthService } from "./common/providers/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SetUserProfile } from "./actions/app";
import { RootState } from "./reducers";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { DocumentListComponent } from "./document-list/document-list.component";
import { SearchComponent } from "./search/search.component";

@Injectable()
export class AuthGuard implements CanLoad {
  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(
    private configService: ConfigService,
    public appStore: Store<RootState>,
    private authService: AuthService) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isCordovaOrElectron) {
      return true;
    } else {
      return this.authService.getProfile().pipe(
        map(profile => {
          if (!profile) {
            window.location.href = this.configService.API_URL + "/api/login";
            return false;
          }
          const userProfile = { ...profile, ...profile.user };
          localStorage.setItem("profileUser", JSON.stringify(userProfile));
          this.appStore.dispatch(new SetUserProfile(userProfile));
          return !!profile;
        }));
    }
  }
}
const routes: Routes = [
  {
    path: "",
    canLoad: [AuthGuard],
    component: DocumentListComponent
  },
  {
    path: "search",
    canLoad: [AuthGuard],
    component: SearchComponent
  }, {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
