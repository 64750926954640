/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { ElectronService } from "./services/electron.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RootState } from "./reducers";
import { Store } from "@ngrx/store";
import { SetLanguage } from "./actions/app";

@Injectable()
export class ConfigService {
  private loginIFrameHandled = false;

  private lang = ["en", "de"];

  private config = {
    "appsEnabled": [],
    "lang": "en",
    "avatarURL" : process.env.AVATAR_URL ||  "https://avatar.vnc.biz"
  };
  GOOGLE_STATIC_MAP_API_KEY = "AIzaSyDuvK3ofJGLlmeOaQ1WPApAumWbVENDgd0";
  AVATAR_SYNC_INTERVAL = 5 * 60 * 1000; // 5 mins
  appInBackground = false;
  selectedServer: any;
  API_URL: string;
  URLS: any = {};
  currentLanguage = new BehaviorSubject<string>("en");
  private config$ = new BehaviorSubject<any>(this.config);
  language = "en";
  hideProfile: boolean = false;
  constructor(private http: HttpClient,
    private translate: TranslateService,
    private store: Store<RootState>,
    private electronService: ElectronService) {
    this.API_URL = "";
    this.setLanguage();
    this.loadConfig();
  }

  getAllConfig() {
    return this.config$.asObservable();
  }

  setCurrentLanguage(selectedLanguage: string) {
    this.language = selectedLanguage;
    this.store.dispatch(new SetLanguage(selectedLanguage));
  }

  setLanguage() {
    let selectedLanguage = null;
    let browserLang = this.translate.getBrowserLang();
    const localLang = this.electronService.isElectron
      ? this.electronService.getFromStorage("portalLanguage")
      : localStorage.getItem("portalLanguage");
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      browserLang = localLang;
    }
    selectedLanguage = browserLang.match(/en|de/) ? browserLang : "en";
    this.updateLanguage(selectedLanguage);
  }

  updateLanguage(selectedLanguage: any) {
    this.language = selectedLanguage;
    this.store.dispatch(new SetLanguage(selectedLanguage));
    this.language = selectedLanguage;
    this.translate.use(selectedLanguage);
  }

  loadConfig(): void {
    if (!!localStorage.getItem("URLS")) {
      this.URLS = JSON.parse(localStorage.getItem("URLS"));
    }
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    if (!isCordovaOrElectron) {
      this.setAPIURL("");
      this.selectedServer = true;
    } else {
      const serverURL = this.electronService.isElectron
        ? this.electronService.getFromStorage("serverURL")
        : localStorage.getItem("serverURL");
      if (serverURL) {
        localStorage.setItem("serverURL", serverURL);
        this.selectedServer = true;
        this.setAPIURL(serverURL.trim());
      }
    }
  }

  updateConfig(config: any): void {
    this.config = { ...this.config, ...config };
  }

  set(key: string, value: any): any {
    const oldValue = this.config[key];
    if (oldValue !== value) {
      console.debug("[config.set(" + key + ")]", oldValue, " => ", value);
    }
    this.config[key] = value;
    this.config$.next(this.config);
    return this.config;
  }

  get(key): any {
    const result = this.config[key];
    return result;
  }

  getOrThrow(key): any {
    if (!this.config.hasOwnProperty(key)) {
      throw new Error("Missing '" + key + "' in config");
    }
    return this.config[key];
  }

  setAPIURL(url: string): void {
    this.API_URL = url;
  }

  showError(msg) {
  }

  getSupportedLangs() {
    return this.lang.slice();
  }

  getDefaultLang() {
    return this.lang[0];
  }

  getConfiguredLang() {
    const lang = this.get("lang");
    if (this.lang.indexOf(lang) >= 0) {
      return lang;
    }
    return this.getDefaultLang();
  }

  isAppEnabled(appTitle: string) {
    const apps = this.config["appsEnabled"] || [];
    const app = apps.filter(a => a.title === appTitle)[0];
    return app && app.enabled;
  }

  getConfig() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(this.API_URL + "/api/config", { headers });
  }

  loginIframe() {
    let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
    initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref.split("/index.html")[0];
    const loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();
    }
    if (!this.selectedServer) {
      return;
    }

    const iframe = document.createElement("iframe");

    if (iframe) {
      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "1111";
      iframe.style.border = "none";
      iframe.style.border = "none";

      iframe.onload = () => {
        iframe.contentWindow.document.querySelector("html").style.height = "100%";
      };

      if (document.querySelector("vp-app") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("vp-app").appendChild(iframe);
      } else {
        if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
          document.querySelector("body").appendChild(iframe);
        }
      }
    } else {
    }
  }

  logoutIframe() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    const iframe = document.createElement("iframe");
    if (iframe) {
      iframe.id = "logoutIframe";
      iframe.setAttribute("src", this.API_URL + "/api/logout/callback");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      if (document.querySelector("body") !== null && document.querySelector("#logoutIframe") === null) {
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        document.querySelector("body").appendChild(iframe);
      }
      if (document.querySelector("vp-commander") !== null) {
        const commander = <HTMLElement>document.querySelector("vp-commander");
        commander.style.display = "none";
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  handleLoginResize() {
    // Added this because of the following reasons:
    // 1. When adding iFrame with position: fixed, scrolling doesn't work...
    // 1. When adding iFrame with position: absolute, white bar shows on startup and disappears on opening keyboard...
    if (!this.loginIFrameHandled) {
      this.loginIFrameHandled = true;
      document.getElementById("loginIframe").style.position = "absolute";
    }
  }
}
