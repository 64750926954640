
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  --> 
<vp-header *ngIf="isLoggedIn"></vp-header>
<div fxLayout="row" id="commander-app">
  <div *ngIf="isLoggedIn" vpResizable id="commander-sidebar">
    <vp-sidebar (sidebarOpened)="onSidebarOpened($event)"></vp-sidebar>
    <div class="resizable-icon">
      <img class="resizable-handler" src="assets/handle-vertical.svg">
    </div>
  </div>
  <div fxFlex id="commander-component" [class.sidebar-expanded]="isSidebarExpanded" [class.show-filters-bar]="showFilters">
    <router-outlet></router-outlet>
    <vp-contacts-list *ngIf="isSidebarExpanded"></vp-contacts-list>
  </div>

  <div *ngIf="showActions" class="create-actions-list">
    <ul>
      <li>
        <span>{{ 'EVENT' | translate }}</span>
        <button class="calendar-color" mat-icon-button (click)="createEvent()">
          <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-calendar"></mat-icon>
        </button>
      </li>
      <li>
        <span>{{ 'INCIDENT' | translate }}</span>
        <button class="incident-color" mat-icon-button (click)="createIncident()">
          <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-message-alert"></mat-icon>
        </button>
      </li>
      <li>
        <span>{{ 'TICKET' | translate }}</span>
        <button class="ticket-color" mat-icon-button (click)="createTicket()">
          <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-ticket"></mat-icon>
        </button>
      </li>
      <li>
        <span>{{ 'TASK' | translate }}</span>
        <button class="task-color" mat-icon-button (click)="createTask()">
          <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-clipboard-check"></mat-icon>
        </button>
      </li>
      <li>
        <span>{{ 'EMAIL' | translate }}</span>
        <button class="mail-color" mat-icon-button (click)="createEmail()">
          <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
        </button>
      </li>
      <li>
        <span>{{ 'CHAT' | translate }}</span>
        <button class="chat-color" mat-icon-button (click)="selectActivity()">
          <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-message-reply-text"></mat-icon>
        </button>
      </li>
    </ul>
  </div>
  <button mat-fab id="createNewFabButton" (click)="showActions = !showActions">
    <mat-icon class="mat-18" *ngIf="!showActions">add</mat-icon>
    <mat-icon class="mat-18" *ngIf="showActions">close</mat-icon>
  </button>
</div>
<vp-authentication *ngIf="!configService.selectedServer"></vp-authentication>
