<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div fxLayout="row" id="document-top-bar" *ngIf="isLoggedIn && !isSearchView">
    <div class="document-option">
      <span class="global_text_change">Global </span>

        <vnc-chips
          (clickEvent)="toggleHideComments()"
          type="filter"
          class="pad_chips bg_size"
          size="s"
          [active]="hideAllComments ? true : false"
          leftIcon="mdi-message-reply">
        </vnc-chips>

        <vnc-chips
          (clickEvent)="filterFavorite()"
          type="filter"
          class="pad_chips bg_size"
          size="s"
          [active]="isFavorite ? true : false"
          [leftIcon]="isFavorite ? 'mdi-star-outline' : 'mdi-star'">
        </vnc-chips>

        <vnc-chips
          (clickEvent)="updateUnreadStatus()"
          type="filter"
          class="pad_chips bg_size"
          size="s"
          [active]="unreadOnly ? true : false"
          leftIcon="unread">
        </vnc-chips>
         
        <vnc-chips
        [text]="allApplicationText"
        (clickEvent) = "openApplicationSmart()"
        rightIcon="mdi-chevron-down"
        
        size="s" type="filter"></vnc-chips>

        <div class="hide_smart_tag application_smart">
          <vnc-smart-link-filter-chip id="application-smart-filter" [disableApplyOnChange]="true"
          customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true" [showResetIcon]="true"
          (apply)="toggleApp($event)"
          applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
          (clearFilter)="setOrderBy($event)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT" 
           [options]="availableAppsOptions"></vnc-smart-link-filter-chip>
           
           <ng-template #tagIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                <defs>
                    <filter id="kelor6mofa" width="119.2%" height="119.7%" x="-9.6%" y="-9.8%" filterUnits="objectBoundingBox">
                        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"/>
                        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
                        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6"/>
                        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0"/>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"/>
                            <feMergeNode in="shadowMatrixOuter2"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g fill="#8B96A0">
                        <g filter="url(#kelor6mofa)" transform="translate(-1587 -5259) translate(1545 4622)">
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path d="M8.25 1.5c.375 0 .694.111.958.333l.097.089 6.75 6.75c.28.281.422.633.422 1.055 0 .375-.112.694-.334.958l-.088.096-5.274 5.274c-.281.28-.633.422-1.054.422-.375 0-.695-.112-.959-.334l-.096-.088-6.75-6.75c-.25-.25-.389-.556-.417-.917L1.5 8.25V2.977c0-.422.14-.774.422-1.055.25-.25.555-.389.917-.417l.138-.005H8.25zm.01 1.498L8.25 3H3v5.25l-.002.01 6.718 6.717h.02l5.241-5.24V9.715L8.26 2.998zm-3.39.764c.316 0 .58.105.79.316.211.211.317.475.317.791 0 .317-.106.58-.317.791-.21.211-.48.317-.808.317-.305 0-.563-.106-.774-.317-.21-.21-.316-.474-.316-.79 0-.317.105-.58.316-.792.211-.21.475-.316.791-.316z" transform="translate(24 146) translate(0 468) translate(16 20) translate(3 3)"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </ng-template>           

        </div>

        <vp-datepicker #datePickerWidget
          [lang]="lang"
          [selected]="selected" 
          class="btn_header"
          [showCustomRangeLabel]="true"
          [alwaysShowCalendars]="true"
          [showClearButton]="true"
          [showCancel]="true"
          [linkedCalendars]="true"
          [keepCalendarOpeningWithRange]="true"
          [showRangeLabelOnInput]="true"
          [placeholder]="'LAST_THREE_MONTH' | translate"
          (datesUpdatedEvent)="datesUpdated($event)"
          (resetDateFilterEvent)="resetDateFilter()"
          [matTooltip]="'SELECT_DATE_RANGE' | translate"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'document-filter-button-tooltip'"
        >
        <mat-icon class="mat-18">keyboard_arrow_down</mat-icon>
      </vp-datepicker> 
    
 

      <span class="border_verical pad_chips"></span>
      <span class="global_text_filter">Filters</span>

      <vnc-chips
      [text]="tagsText"
      class="pad_chips"
      (clickEvent) = "filterTags()"
       rightIcon="mdi-chevron-down"
      size="s" type="filter">
      </vnc-chips>

      <div class="hide_smart_tag tags_smart">
        <vnc-smart-link-filter-chip id="tags-smart-filter" [disableApplyOnChange]="true"
        customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true"
        (apply)="setTags($event)"
        [showResetIcon]="true"
        applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
        (clearFilter)="setTags($event.value)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT" 
         [options]="tags"></vnc-smart-link-filter-chip>  
      </div>

      <vnc-chips
        [text]="selectedUsers"
        (clickEvent) = "openUserFilter()"
        class="pad_chips"
        rightIcon="mdi-chevron-down"
        size="s" type="filter">
      </vnc-chips>

      <div class="hide_smart_tag">
        <mat-icon class="mat-18 reset-user-icon-filter" (click)="resetUserFilter()" *ngIf="selectedUsers!=='Users'">close</mat-icon>
      </div>
      
      <div class="hide_smart_tag user_smart">
        <vnc-smart-link-filter-chip  id="user-smart-filter" [searchPlaceholder]="'SEARCH' | translate"  [applyLabel]="'APPLY'" [cancelLabel]="'CANCEL'" [multiple]="true"  type="SELECT" 
        (clearFilter)="applyAllUserFilters($event)" (apply)="applyAllUserFilters($event)"  [options]="contacts" 
        [showClearIcon]="true" 
        [showSearchBox]="true"
        [showResetIcon]="true"
        [showOperatorOption]="true"></vnc-smart-link-filter-chip> 
      </div> 

      <vnc-chips
        [text]="selectedAuthors"
        (clickEvent) = "openAuthorFilter()"
        rightIcon="mdi-chevron-down"
       
        size="s" type="filter">
      </vnc-chips>

      <div class="hide_smart_tag">
        <mat-icon class="mat-18 reset-author-icon-filter" (click)="resetAuthorsFilter()" *ngIf="selectedAuthors!=='Authors'">close</mat-icon>
      </div>
      
      <div class="hide_smart_tag author_smart">
        <vnc-smart-link-filter-chip  id="author-smart-filter" [searchPlaceholder]="'SEARCH' | translate"  [applyLabel]="'APPLY'" [cancelLabel]="'CANCEL'" [multiple]="true"  type="SELECT" 
        (clearFilter)="applyAllAuthorFilters($event)" (apply)="applyAllAuthorFilters($event)"  [options]="contacts" 
        [showClearIcon]="true" 
        [showSearchBox]="true"
        [showResetIcon]="true"
        [showOperatorOption]="true">
        </vnc-smart-link-filter-chip> 
      </div>
      
      <span class="border_verical pad_chips"></span>
      <span class="order_text_change">Order </span>

      <vnc-chips
        class="pad_chips "
        [text]="orderByText"
        (clickEvent) = "openOrderByFilter()"
        rightIcon="mdi-chevron-down"
        size="s" type="filter">
        
      </vnc-chips>

      <div class="hide_smart_tag order_by">
        <vnc-smart-link-filter-chip id="order-by-smart-filter" [disableApplyOnChange]="true"
        customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true"
        (apply)="setOrderBy($event)"
        applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
        (clearFilter)="setOrderBy($event)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT" [showResetIcon]="true"
         [options]="orderByOptions"></vnc-smart-link-filter-chip>  
      </div>

      <vnc-chips
        [text]="groupByText"
        (clickEvent) = "openGroupByFilter()"
        rightIcon="mdi-chevron-down"
        size="s" type="filter">
      </vnc-chips>

      <div class="hide_smart_tag group_by">
        <vnc-smart-link-filter-chip id="group-by-smart-filter" [disableApplyOnChange]="true"
        customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true"
        (apply)="setGroupBy($event)"
        applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
        (clearFilter)="setGroupBy($event)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT" [showResetIcon]="true"
         [options]="groupByOptions"></vnc-smart-link-filter-chip>  
      </div>
   
    <div class="right-options">
      <div class="document-option show-unread-option">
        <button id="viewByOption" mat-icon-button [matTooltip]="'SELECT_POSTS_VIEW' | translate"
          [matTooltipPosition]="'above'" [matTooltipClass]="'document-filter-button-tooltip'"
          [matMenuTriggerFor]="appMenu">
          <mat-icon class="current-view tog_btn"  fontSet="mdi" *ngIf="viewBy === 'condense'" fontIcon="mdi-view-headline">
          </mat-icon>
          <mat-icon class="current-view tog_btn" fontSet="mdi" *ngIf="viewBy === 'detail'" fontIcon="mdi-view-stream"></mat-icon>
          <vnc-icon type='product' name='view-tile-grid' width='16px' height='16px' *ngIf="viewBy === 'tile'" class="current-view-tile"></vnc-icon>
        </button>
        <mat-menu #appMenu="matMenu" [class]="'view-by-option-menu'">
          <ng-template matMenuContent>
            <div (click)="setViewBy('table')" [ngClass]="{'detailViewActive' : viewBy === 'table'}">
              <vp-dropdown-item [itemType]="'icon-drop-default'" label="{{ 'TABLE_VIEW' | translate }}"
                sufixImageType="{{viewBy === 'table' ? 'check' : null}}" [size]="'m'"
                [prefixIconTemplate]="detailIconTemplate"
                [sufixIconTemplate]="viewBy === 'table' ? checkedDetailIconTemplate : null">
              </vp-dropdown-item>
              <ng-template #detailIconTemplate>
                <svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="list-ul"
                  class="svg-inline--fa fa-list-ul fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M48 48a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 16H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" fill="#626262">
                  </path>
                </svg>
              </ng-template>
              <ng-template #checkedDetailIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                  focusable="false" width="1em" height="1em"
                  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                  <path d="M9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414" fill="#337cbd" />
                </svg>
              </ng-template>
            </div>
  
            <div (click)="setViewBy('condense')" [ngClass]="{'condenseViewActive' : viewBy === 'condense'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'CONDENSED_VIEW' | translate }}"
              sufixImageType="{{viewBy === 'condense' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="condensedIconTemplate"
              [sufixIconTemplate]="viewBy === 'condense' ? checkedCondenseIconTemplate : null" >
              </vp-dropdown-item>
              <ng-template #condensedIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="18" height="18" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4 5v2h17V5M4 11h17V9H4m0 10h17v-2H4m0-2h17v-2H4v2z" fill="#626262"/></svg>
              </ng-template> 
            </div>
            <ng-template #checkedCondenseIconTemplate>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414" fill="#337cbd"/></svg>
            </ng-template> 
  
  
  
            <div (click)="setViewBy('detail')" [ngClass]="{'detailViewActive' : viewBy === 'detail'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'DETAILED_VIEW' | translate }}"
              sufixImageType="{{viewBy === 'detail' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="detailIconTemplate"
              [sufixIconTemplate]="viewBy === 'detail' ? checkedDetailIconTemplate : null">
              </vp-dropdown-item>
              <ng-template #detailIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="18" height="18" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4 5v6h17V5M4 18h17v-6H4v6z" fill="#626262"/></svg>
              </ng-template> 
              <ng-template #checkedDetailIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414" fill="#337cbd"/></svg>
              </ng-template> 
            </div>

  
            <div (click)="setViewBy('tile')" [ngClass]="{'tileViewActive' : viewBy === 'tile'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'TILE_VIEW' | translate }}"
              sufixImageType="{{viewBy === 'tile' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="tileIconTemplate"
              [sufixIconTemplate]="viewBy === 'tile' ? checkedTileIconTemplate : null">
              </vp-dropdown-item>
              <ng-template #tileIconTemplate>
                <vnc-icon type='product' name='view-tile-grid' width='16px' height='16px' *ngIf="viewBy !== 'tile'" class="tile-view-icon"></vnc-icon>
                <vnc-icon type='product' name='view-tile-grid' width='16px' height='16px' class="current-view-tile" *ngIf="viewBy === 'tile'"></vnc-icon>
              </ng-template> 
              <ng-template #checkedTileIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414" fill="#337cbd"/></svg>
              </ng-template>  
            </div>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<div id="document-list" vpScrollList (onUserScroll)="onUserScroll($event);">
  <div class="new-posts-notification" *ngIf="isLoggedIn && newMessageCount > 0" (click)="refreshFeed()">
    <mat-icon>arrow_upward</mat-icon> {{'SEE_NEW_POSTS' | translate }}</div>
  <ng-container *ngIf="groupBy === 'none'">
    <div class="document-item" *ngFor="let document of documents">
      <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
      <vp-condensed-view *ngIf="viewBy === 'condense'" [document]="document"></vp-condensed-view>
    </div>
    <div *ngIf="viewBy === 'tile' && groupBy === 'none'">
      <div *ngIf="talkFeeds && talkFeeds.length > 0">
        <div class="flex-cove">
          <img class="document-item app-logo-cat" src="assets/icon/new-icons/vnctalk.svg" />
          <p class="app-logo-cat-text">VNCtalk<span class="sub-text"> ({{talkFeeds.length}})</span></p>
          <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
        </div>
        <div class="tile-view-container">
          <div class="document-item" *ngFor="let document of talkFeeds">
            <vp-tile-view  [document]="document"></vp-tile-view>
          </div> 
        </div>           
      </div>

      <div *ngIf="mailFeeds && mailFeeds.length > 0" class="tile-cat-sec">
        <div class="flex-cove">
          <img class="document-item app-logo-cat" src="assets/icon/new-icons/vncmail.svg" />
          <p class="app-logo-cat-text">VNCmail<span class="sub-text"> ({{mailFeeds.length}})</span></p>
          <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
        </div>
        <div class="tile-view-container">
          <div class="document-item" *ngFor="let document of mailFeeds">
            <vp-tile-view  [document]="document"></vp-tile-view>
          </div> 
        </div>           
      </div>      

    </div>
  </ng-container>
  <ng-container *ngIf="groupBy === 'application'">
    <section class="docs-by-app" *ngFor="let app of apps">
      <div class="app-header {{app}}-app" (click)="toggleStatus[app] = !toggleStatus[app]">
        <div class="app-name">
            VNC{{ app }}
            <span class="app-results-count" *ngIf="appsList[app]">{{ appsList[app].length }}</span>
        </div>
        <span></span>
        <button mat-icon-button (click)="$event.stopPropagation(); toggleStatus[app] = !toggleStatus[app]">
          <mat-icon *ngIf="toggleStatus[app]" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
          <mat-icon *ngIf="!toggleStatus[app]" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </button>
      </div>
      <div class="app-content" *ngIf="!toggleStatus[app]">
        <div class="document-item" *ngFor="let document of appsList[app]">
          <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
          <vp-condensed-view *ngIf="viewBy === 'condense'" [document]="document"></vp-condensed-view>
        </div>
        <div *ngIf="viewBy === 'tile' && !toggleStatus[app]">
          <div *ngIf="talkFeeds && talkFeeds.length > 0">
            <div class="flex-cove">
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vnctalk.svg" />
                <p class="app-logo-cat-text">VNCtalk<span class="sub-text"> ({{talkFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>  
            </div>
            <div class="tile-view-container">
              <div class="document-item" *ngFor="let document of talkFeeds">
                <vp-tile-view  [document]="document"></vp-tile-view>
              </div> 
            </div>           
          </div>

          <div *ngIf="mailFeeds && mailFeeds.length > 0" class="tile-cat-sec">
            <div class="flex-cove">
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vncmail.svg" />
                <p class="app-logo-cat-text">VNCmail<span class="sub-text"> ({{mailFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
              </div>
            <div class="tile-view-container">
              <div class="document-item" *ngFor="let document of mailFeeds">
                <vp-tile-view  [document]="document"></vp-tile-view>
              </div> 
            </div>           
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="groupBy === 'user'">
    <section class="docs-by-app docs-by-user" *ngFor="let user of users">
      <div class="app-header app-header-default" (click)="toggleStatus[user] = !toggleStatus[user]">
        <div class="app-name">
            {{ user | vpGetFullName }}
            <span class="app-results-count" *ngIf="usersList[user]">{{ usersList[user].length }}</span>
        </div>
        <span></span>
        <button mat-icon-button (click)="$event.stopPropagation(); toggleStatus[user] = !toggleStatus[user]">
          <mat-icon *ngIf="toggleStatus[user]" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
          <mat-icon *ngIf="!toggleStatus[user]" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </button>
      </div>
      <div class="app-content" *ngIf="!toggleStatus[user]">
        <div class="document-item" *ngFor="let document of usersList[user]">
          <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
          <vp-condensed-view *ngIf="viewBy === 'condense'" [document]="document"></vp-condensed-view>
        </div>
        <div *ngIf="viewBy === 'tile' && !toggleStatus[user]">
            <div *ngIf="talkFeeds && talkFeeds.length > 0">
              <div>
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vnctalk.svg" />
                <p class="app-logo-cat-text">VNCtalk<span class="sub-text"> ({{talkFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
              </div>
              <div class="tile-view-container">
                <div class="document-item" *ngFor="let document of talkFeeds">
                  <vp-tile-view  [document]="document"></vp-tile-view>
                </div> 
              </div>           
            </div>

            <div *ngIf="mailFeeds && mailFeeds.length > 0" class="tile-cat-sec">
              <div>
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vncmail.svg" />
                <p class="app-logo-cat-text">VNCmail<span class="sub-text"> ({{mailFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
              </div>
              <div class="tile-view-container">
                <div class="document-item" *ngFor="let document of mailFeeds">
                  <vp-tile-view  [document]="document"></vp-tile-view>
                </div> 
              </div>           
            </div>     
        </div>        
      </div>
    </section>
  </ng-container>
  <vnc-no-results *ngIf="totalResults === 0 && !isLoading" description="" message="{{ 'NO_RESULTS' | translate }}"></vnc-no-results>   
</div>

<span class="Loading app-loading" *ngIf="isLoading">
  <div class="loading_wrap">
    <div class="loader"></div>  &nbsp;&nbsp;&nbsp; Loading
  </div>
</span>
