/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { AppState } from "../reducers/app";
import { UserProfile } from "../common/models/mail-models/user-profile";
import { AppModel } from "../common/models/app.model";
import { MessageActionTypes } from "./message";
import { Message } from "../common/models/message.model";
import { Tag } from "../common/models/tag.model";

export class AppActionTypes {
  static LOGIN_REQUEST = "[App] Login Request";
  static LOGIN_SUCCESS = "[App] Login Success";
  static LOGIN_FAILED = "[App] Login Failed";
  static LOGOUT = "[App] logout";
  static SET_SIDEBAR_STATUS = "[App] Expand-Collapse Sidebar";
  static SET_USER_PROFILE = "[App] Set User Profile";
  static APP_ONLINE = "[App] App Online";
  static DEVICE_READY = "[App] Device Ready";

  static RESTORE_SAVED_STATE = "[App] App Restore saved state";
  static START_PROCESSING = "[App] Start processing action";
  static STOP_PROCESSING = "[App] Stop processing action";
  static SET_FIREBASE_TOKEN = "[App] set firebase token";
  static SET_LAST_PHOTO_UPDATE = "[APP] Set Last Photo Update";
  static RESTORE_LAST_PHOTO_UPDATE = "[APP] Restore Last Photo Update";
  static RESET_LAST_PHOTO_UPDATE = "[APP] Reset Last Photo Update";
  static SET_UNREAD_ONLY = "[APP] Set Unread Only";
  static SET_HIDE_ALL_COMMENTS = "[APP] Hide All Comments";
  static SET_ORDER_BY = "[APP] Set Order By";
  static SET_VIEW_BY = "[APP] Set View By";
  static SET_GROUP_BY = "[APP] Set Group By";
  static SET_SEARCH_STRING = "[APP] Set Search String";
  static TOGGLE_ACTIVE_APP = "[APP] Toggle Active App";
  static SET_AVAILABLE_APPS = "[APP] Set Available Apps";
  static SET_COUNT_FOR_APPS = "[APP] Set Count for Apps";
  static SET_UNREAD_COUNT_FOR_APPS = "[APP] Set Unread Count for Apps";
  static SET_NOTIFICATION_SETTINGS = "[APP] Set Notification Settings";
  static SET_LANGUAGE = "[APP] Set Language";
  static SET_USER_CONTACTS = "[APP] Set User Contacts";
  static SET_TOTAL_PER_PAGE = "[APP] Set Total Per Page";
  static SET_GAL_CONTACTS = "[APP] Set Gal Contacts";
  static XMPP_CONNECTED = "[App] XMPP Connected";
  static UPDATE_CONNECTION_INFORMATION = "[App] Update Network Information";
  static XMPP_SESSION = "[App] Xmpp Session";
  static SET_BARE = "[App] Set bare";
  static SET_TAGS = "[App] Set Tags";
  static SET_DATE_RANGE = "[App] Set Date range";
  static SET_SHOW_FILTERS = "[App] Set Show Filters";
  static SET_ADVANCED_SEARCH = "[App] Set Advanced Search";
  static SET_ACTIVE_APP = "[App] Set Active Apps";
  static SET_PROJECTS = "[App] Set Projects";
  static SET_PRIORITIES = "[App] Set Priorities";
  static SET_STATUSES = "[App] Set Statues";
  static SET_PROJECT_DATA = "[App] Set Project Data";
}


export class Logout implements Action {
  readonly type = AppActionTypes.LOGOUT;
}

export class LoginRequest implements Action {
  readonly type = AppActionTypes.LOGIN_REQUEST;
}

export class SetSidebarStatus implements Action {
  readonly type = AppActionTypes.SET_SIDEBAR_STATUS;
  constructor(public payload: boolean) {
  }
}

export class SetBare implements Action {
  readonly type = AppActionTypes.SET_BARE;
  constructor(public payload: string) {}
}

export class SetUserProfile implements Action {
  readonly type = AppActionTypes.SET_USER_PROFILE;
  constructor(public payload: UserProfile) {
  }
}

export class LoginSuccess implements Action {
  readonly type = AppActionTypes.LOGIN_SUCCESS;
  constructor() {
  }
}

export class SetActiveApps implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_APP;
  constructor(public payload: string[]) {
  }
}

export class LoginFailed implements Action {
  readonly type = AppActionTypes.LOGIN_FAILED;
  constructor() {
  }
}

export class OnlineStatus implements Action {
  readonly type = AppActionTypes.APP_ONLINE;
  constructor(public payload: boolean) {
  }
}

export class RestoreSavedState implements Action {
  readonly type = AppActionTypes.RESTORE_SAVED_STATE;
  constructor(public payload: {
    appState: AppState
  }) {
  }
}

export class StartProcessing implements Action {
  readonly type = AppActionTypes.START_PROCESSING;
  constructor() {
  }
}

export class StopProcessing implements Action {
  readonly type = AppActionTypes.STOP_PROCESSING;
  constructor() {
  }
}

export class SetFirebaseToken implements Action {
  readonly type = AppActionTypes.SET_FIREBASE_TOKEN;

  constructor(public payload: string) {
  }
}

export class SetLastPhotoUpdate implements Action {
  readonly type = AppActionTypes.SET_LAST_PHOTO_UPDATE;
  constructor(public payload: any) {
  }
}

export class RestoreLastPhotoUpdate implements Action {
  readonly type = AppActionTypes.RESTORE_LAST_PHOTO_UPDATE;
  constructor(public payload: any) {
  }
}

export class SetUnreadOnly implements Action {
  readonly type = AppActionTypes.SET_UNREAD_ONLY;
  constructor(public payload: boolean) {
  }
}

export class SetHideAllComments implements Action {
  readonly type = AppActionTypes.SET_HIDE_ALL_COMMENTS;
  constructor(public payload: boolean) {
  }
}

export class SetOrderBy implements Action {
  readonly type = AppActionTypes.SET_ORDER_BY;
  constructor(public payload: string) {
  }
}

export class SetSearchString implements Action {
  readonly type = AppActionTypes.SET_SEARCH_STRING;
  constructor(public payload: string) {
  }
}

export class ToggleActiveApp implements Action {
  readonly type = AppActionTypes.TOGGLE_ACTIVE_APP;
  constructor(public payload: string) {
  }
}

export class SetGroupBy implements Action {
  readonly type = AppActionTypes.SET_GROUP_BY;
  constructor(public payload: string) {
  }
}

export class SetViewBy implements Action {
  readonly type = AppActionTypes.SET_VIEW_BY;
  constructor(public payload: string) {
  }
}

export class SetCountForApps implements Action {
  readonly type = AppActionTypes.SET_COUNT_FOR_APPS;
  constructor(public payload: any) {
  }
}

export class SetUnreadCountForApps implements Action {
  readonly type = AppActionTypes.SET_UNREAD_COUNT_FOR_APPS;
  constructor(public payload: any) {
  }
}

export class ResetLastPhotoUpdate implements Action {
  readonly type = AppActionTypes.RESET_LAST_PHOTO_UPDATE;
  constructor() {
  }
}

export class SetAvailableApps implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_APPS;

  constructor(public payload: AppModel[]) {
  }
}

export class XmppSession implements Action {
  readonly type = AppActionTypes.XMPP_SESSION;

  constructor(public payload: any) {
  }
}

export class SetXmppConnection implements Action {
  readonly type = AppActionTypes.XMPP_CONNECTED;

  constructor(public payload: boolean) {
  }
}

export class UpdateNetworkInformation implements Action {
  readonly type = AppActionTypes.UPDATE_CONNECTION_INFORMATION;

  constructor(public payload: any) {
  }
}

export class SetNotificationSettings implements Action {
  readonly type = AppActionTypes.SET_NOTIFICATION_SETTINGS;

  constructor(public payload: {[app: string]: boolean}) {
  }
}

export class SetLanguage implements Action {
  readonly type = AppActionTypes.SET_LANGUAGE;

  constructor(public payload: string) {
  }
}


export class SetMessageToReply implements Action {
  readonly type = MessageActionTypes.SET_MESSAGE_TO_REPLY;

  constructor(public payload: Message) {
  }
}

export class SetTags implements Action {
  readonly type = AppActionTypes.SET_TAGS;

  constructor(public payload: Tag[]) {
  }
}

export class SetDateRange implements Action {
  readonly type = AppActionTypes.SET_DATE_RANGE;

  constructor(public payload: any) {
  }
}

export class SetTotalPerPage implements Action {
  readonly type = AppActionTypes.SET_TOTAL_PER_PAGE;

  constructor(public payload: number) {
  }
}

export class SetShowFilters implements Action {
  readonly type = AppActionTypes.SET_SHOW_FILTERS;

  constructor(public payload: boolean) {
  }
}

export class SetAdvancedSearch implements Action {
  readonly type = AppActionTypes.SET_ADVANCED_SEARCH;

  constructor(public payload: any) {
  }
}

export class SetProjects implements Action {
  readonly type = AppActionTypes.SET_PROJECTS;

  constructor(public payload: any) {
  }
}

export class SetStatues implements Action {
  readonly type = AppActionTypes.SET_STATUSES;

  constructor(public payload: any) {
  }
}

export class SetPriorities implements Action {
  readonly type = AppActionTypes.SET_PRIORITIES;

  constructor(public payload: any) {
  }
}

export class SetProjectData implements Action {
  readonly type = AppActionTypes.SET_PROJECT_DATA;

  constructor(public payload: any) {
  }
}

export class DeviceReady implements Action {
  readonly type = AppActionTypes.DEVICE_READY;
  constructor(public payload: boolean) {
  }
}
