
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="!chatType" class="avatar" [ngClass]="type" [ngStyle]="{'background-color': getColor()}">
  <img draggable="false" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (error)="imgLoadOnError()">
  <mat-icon *ngIf="user && !avatarURL" class="profile-avatar" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
</div>
<div *ngIf="chatType" class="avatar {{ chatType }}" [ngClass]="type" [ngStyle]="{'background-color': getColor()}">
  <ng-container *ngIf="chatType === 'chat'">
      <img draggable="false" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (error)="imgLoadOnError()">
      <mat-icon *ngIf="user && !avatarURL" class="profile-avatar" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
  </ng-container>
  <ng-container *ngIf="chatType === 'broadcast'">
      <mat-icon class="profile-avatar" fontSet="mdi" fontIcon="mdi-bullhorn"></mat-icon>
  </ng-container>
  <ng-container *ngIf="chatType === 'groupchat'">
    <img draggable="false" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (error)="imgLoadOnError()">
    <mat-icon *ngIf="!avatarURL" class="profile-avatar" fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
  </ng-container>
</div>
